import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import Rodal from 'rodal'
import { FilterList, Close } from '@material-ui/icons'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        users: false,
        delete: false
    })
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState([])
    const [updateStatus, setUpdateStatus] = useState({})
    const [filter, setFilter] = useState({
        name: '',
        tax_code: false,
        type: false,
        active: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const getUsers = async () => {
        setLoaders({ ...loaders, users: true })

        try {
            let filters = '?'

            if (filter.name) {
                filters += `name=${filter.name}&`
            }
            if (filter.cpf) {
                filters += `cpf=${filter.cpf}&`
            }
            if (filter.type) {
                filters += `type=${filter.type}&`
            }
            if (filter.active) {
                filters += `active=${filter.active}&`
            }

            filters = filters.substring(filters[0], filters.length - 1)
            let res
            console.log('---', filters)
            if (filters) {
                res = await api.get(`/user${filters}`)
                setData(res.data)
            } else {
                res = await api.get(`/user`)
                setData(res.data)
            }
            setCurrentPage(1)
        } catch (err) {
            console.log(err)

        }

        setLoaders({ ...loaders, users: false })
    }

    const updateStatusUsers = async () => {
        setLoaders({ ...loaders, delete: true })

        try {
            const response = await api.delete(`/user/${updateStatus.uuid}`)
            getUsers()
            setShowModal(false)
        } catch (err) {
            console.log(err)
        }

        setLoaders({ ...loaders, delete: false })
    }

    useEffect(() => { getUsers() }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">

                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                />

                                {/* <CurrencyFormat format="###.###.###-##" mask="_"
                                    placeholder="CPF"
                                    className="form-control"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.cpf}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, cpf: formattedValue })
                                    }}
                                /> */}

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    onClick={(e) => setFilter({ ...filter, type: e.target.value })}
                                >
                                    <option value="">TODOS</option>
                                    <option value="admin">ADMINISTRADORES</option>
                                    <option value='user'>USUÁRIOS</option>
                                    <option value='driver'>PARCEIROS USUÁRIOS</option>
                                </select>

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '200px', height: '35px' }}
                                    onClick={(e) => setFilter({ ...filter, active: e.target.value })}
                                >
                                    <option value="">TODOS USUÁRIOS</option>
                                    <option value='true'>APENAS ATIVOS</option>
                                    <option value='false'>APENAS INATIVOS</option>
                                </select>

                                <button disabled={loaders.business} onClick={() => getUsers()} style={{
                                    width: '150px',
                                    marginLeft: 10
                                }} type="button" className="btn btn-info">
                                    {
                                        loaders.business ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Processando...
                                            </>
                                            : 'Pesquisar'
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            {
                                loaders.users ?
                                    <div className="loader">
                                        <h5>
                                            <Loader />
                                            <br />
                                            Carregando Usuários...
                                        </h5>
                                    </div>
                                    : data.length > 0 ?
                                        <section className="form-create-users" style={{ display: 'flex' }}>
                                            <label className="text-label-title"><i className="fas fa-users"></i>&nbsp;Gerenciar Usuários</label>
                                            {/* <div className="separator" /> */}
                                            <div className="row-forms" style={{ flexDirection: 'column' }}>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc">ID</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nome</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">E-Mail</th>
                                                            <th className="sorting_asc">Perfil</th>
                                                            <th className="sorting_asc">Ativo</th>
                                                            <th className="sorting_asc">Editar</th>
                                                            <th className="sorting_asc">Desativar</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentPosts.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                                        {row.name ? row.name : 'SEM NOME'}
                                                                    </td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">
                                                                        {row.company ? row.company.name : '---'}
                                                                    </td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">{row.email}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            row.id_profile === 1 ? "SYS" :
                                                                                row.id_profile === 2 ? "Administrador" :
                                                                                    row.id_profile === 3 ? "Usuário" :
                                                                                        row.id_profile === 4 ? "Parceiro Usuário" :
                                                                                            "Não Identificado"
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">{row.deleted_at ? "NÃO" : "SIM"}</td>
                                                                    <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i
                                                                                onClick={() => props.history.push('/edit-users', { data: row })}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="la la la-edit blue la-2x"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        row.deleted_at ?
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setUpdateStatus(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-check green la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setUpdateStatus(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-times red la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />

                                            </div>
                                        </section>
                                        :
                                        <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                            <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                        </section>
                            }

                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        updateStatus.deleted_at ?
                                            <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" /> :
                                            <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                    }
                                    <p>Tem certeza que deseja alterar o estado do usuário {updateStatus.name}?</p>
                                    <br />

                                    <button
                                        disabled={loaders.delete}
                                        onClick={() => updateStatusUsers()}
                                        style={{ width: '25%' }}
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        {
                                            loaders.delete
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                updateStatus.deleted_at ? 'Sim. Reative.' : 'Sim. Desative.'
                                        }
                                    </button>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

