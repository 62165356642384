import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'
import ThreeDots from 'react-loader-spinner'

export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const fields = props.location.state.data
  const [data, setData] = useState(fields)
  const UF = [
    ["AC", "Acre"],
    ["AL", "Alagoas"],
    ["AP", "Amapá"],
    ["AM", "Amazonas"],
    ["BA", "Bahia"],
    ["CE", "Ceará"],
    ["DF", "Distrito Federal"],
    ["ES", "Espírito Santo"],
    ["GO", "Goiás"],
    ["MA", "Maranhão"],
    ["MT", "Mato Grosso"],
    ["MS", "Mato Grosso do Sul"],
    ["MG", "Minas Gerais"],
    ["PA", "Pará"],
    ["PB", "Paraíba"],
    ["PR", "Paraná"],
    ["PE", "Pernambuco"],
    ["PI", "Piauí"],
    ["RJ", "Rio de Janeiro"],
    ["RN", "Rio Grande do Norte"],
    ["RS", "Rio Grande do Sul"],
    ["RO", "Rondônia"],
    ["RR", "Roraima"],
    ["SC", "Santa Catarina"],
    ["SP", "São Paulo"],
    ["SE", "Sergipe"],
    ["TO", "Tocantins"]
  ]
  const [showModal, setShowModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [companies, setCompanies] = useState([])
  const [loader, setLoader] = useState(false)
  const [text, setText] = useState('')
  const [loaderCEP, setLoaderCEP] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const getCompanies = async () => {
    try {
      const res = await api.get('/company')

      if (res.data) {
        setCompanies(res.data)
      }
    }
    catch (error) {
      console.log("error =>", error)
    }
  }

  const edit = async () => {
    setLoader(true)

    try {
      const res = await api.put(`/company/${data.id}`, {
        name: data.name,
        tax_code: data.tax_code,
        trading_name: data.trading_name,
        company_type: data.company_type,
        address: data.address,
        number: data.number,
        city: data.city,
        state: data.state,
        district: data.district,
        phone_number: data.phone_number,
        complement: data.complement,
        mail: data.mail,
        cep: data.cep,
        category: data.category,
        pay_day: data.pay_day,
        id_company_partner: data.id_company_partner,
        mdr_value: data.mdr_value,
        mdr_percent: data.mdr_percent
      })

      setText(res.data.message)
      setShowModal(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
      setShowModal(true)
      setText('Erro ao editar Empresa...')
    }
    setLoader(false)
    props.history.push('/list-business')
  }

  const getCEP = async (cep) => {

    setLoaderCEP(true)
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, { mode: 'cors' })
    const data = await response.json()

    setData({
      ...data,
      cep: data.cep,
      address: data.address,
      city: data.city,
      district: data.district,
      state: data.state,
    })
    setLoaderCEP(false)
  }

  const changeMDR = async (value) => {

    let aux = value.replace(',', '')

    if (isNaN(aux) || value.includes('.') || !aux) {
      return
    }

    let tamanho_variavel_atual = data.mdr_value.length
    console.log('tamanho_variavel_atual', tamanho_variavel_atual)

    if (tamanho_variavel_atual < value.length) {
      console.log('value.length', value.length)
      if (value.length == 3) {
        if (!value.includes(',')) {
          value = value + ','
        }
      }
    }

    if (tamanho_variavel_atual > value.length) {
      console.log(tamanho_variavel_atual > value.length)
      if (value.length == 3) {
        value = value.substring(0, 2)
      }
    }
    setData({ ...data, mdr_value: value })
  }

  const changeP_MDR = async (e) => {

    let aux = e.replace(',', '')

    if (isNaN(aux) || !aux) {
      return
    }

    if (e > 100) {
      return
    }

    let valueP_MDR = data.mdr_percent.length

    if (valueP_MDR < e.length) {
      if (e.length == 3) {
        console.log('incluindo')
        if (!e.includes(',')) {
          e = e + ','
        }
      }
      if (e.substring(0, 4) == '100,') {
        e = '100,00'
      }
    }

    if (valueP_MDR > e.length) {
      console.log('apagando')
      if (e.length == 4) {
        e = e.substring(0, 3)
      }
    }

    setData({ ...data, mdr_percent: e })
  }

  // valida CEP
  useEffect(() => {
    if (!data.cep) setData({ ...data, cep: '' })
  }, [data.cep])

  // habilita botao criar
  useEffect(() => {
    setIsDisabled(false)

    if (data.company_type === '' || data.cep === '' || data.tax_code.replace('_', '').length !== 18 ||
      data.trading_name === '' || data.name === '' || data.pay_day === '') {
      setIsDisabled(true)
    } else {
      if ((data.company_type !== '1' && data.company_type !== '') && data.id_company_partner === '') {
        setIsDisabled(true)
      }
    }
  }, [data])

  useEffect(() => { getCompanies() }, [])

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>

            <div className="menu-center">
              <button
                onClick={() => { props.history.push('/list-business') }}
                style={{ width: '150px', marginLeft: 10 }}
                type="button" className="btn btn-info"
              >
                Voltar
              </button>
            </div>

            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel">
              <section className="form-create-users" style={{ height: '95%' }}>
                <label className="text-label-title"><i className="fas fa-building white" /> &nbsp; Edição de Empresa</label>
                <div className="separator" />

                <div className="row-forms">
                  <div className="grid">
                    {/* tipo empresa */}
                    <div className="form-group">
                      <label className="text-label">Tipo Empresa *</label>
                      <select
                        value={data.company_type}
                        className='form-control form-control-lg'
                        disabled
                      >
                        <option value="">-- TIPO EMPRESA--</option>
                        {
                          companies.length > 0 ?
                            <>
                              <option value='1'>Master</option>
                              <option value='2'>Parceiro Comercial</option>
                              <option value='3'>Parceiro Fornecedor</option>
                            </> : <></>
                        }
                      </select>
                    </div>
                    {/* master */}
                    {
                      (data.company_type !== 1 && data.company_type !== '') &&
                      <div className="form-group">
                        <label className="text-label">Empresa Master *</label>
                        <select
                          value={data.id_company_partner}
                          className='form-control form-control-lg'
                          disabled
                        >
                          <option value=""> MASTER </option>
                          {
                            companies.length > 0 ?
                              companies.map(row =>
                                <option value={row.id}>{row.id} - {row.name}</option>
                              )
                              :
                              <option>-- Caregando --</option>


                          }
                        </select>
                      </div>
                    }
                    {/* razao social */}
                    <div className="form-group">
                      <label className="text-label">Razão Social*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Razão Social"
                        required
                        value={data.trading_name}
                        onChange={(e) => setData({ ...data, trading_name: e.target.value })}
                      />
                    </div>
                    {/* nome fantasia */}
                    <div className="form-group">
                      <label className="text-label">Nome Fantasia*</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="Nome Fantasia"
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

                <div className="row-forms">
                  <div className="grid">
                    {/* cnpj */}
                    <div className="form-group">
                      <label className="text-label">CNPJ*</label>
                      <CurrencyFormat format="##.###.###/####-##" mask="_"
                        placeholder="CNPJ"
                        className="form-control"
                        value={data.tax_code}
                        disabled
                      />

                    </div>
                    {/* dias pgto */}
                    <div className="form-group">
                      <label className="text-label">Dias de Pagamento (02)</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Dias de Pagamento"
                        value={data.pay_day}
                        onChange={(e) => setData({ ...data, pay_day: e.target.value })}
                      />
                    </div>
                    {/* ie */}
                    <div className="form-group">
                      <label className="text-label">Inscrição Estadual</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Inscrição Estadual"
                        value={data.state_registration}
                        onChange={(e) => setData({ ...data, state_registration: e.target.value })}
                      />
                    </div>
                    {/* valor mdr */}
                    <div className="form-group">
                      <label className="text-label">Valor MDR</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Valor R$ MDR"
                        value={data.mdr_value ? data.mdr_value : 0}
                        onChange={(e) => changeMDR(e.target.value)}
                        maxLength="6"
                      // onChange={(e) => setData({ ...data, mdr_value: e.target.value })}
                      />
                    </div>
                    {/* valor mdr */}
                    <div className="form-group">
                      <label className="text-label">Percentual MDR</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Valor % MDR"
                        value={data.mdr_percent ? data.mdr_percent : 0}
                        // onChange={(e) => setData({ ...data, mdr_percent: e.target.value })}
                        onChange={(e) => changeP_MDR(e.target.value)}
                        maxLength="6"
                      />
                    </div>
                  </div>
                </div>

                <div className="row-forms">
                  <div className="grid">
                    {/* cep */}
                    {/* cep OBRIGATÓRIO */}
                    <div className="form-group">
                      <label className="text-label">
                        CEP*
                        {
                          loaderCEP &&
                          <ThreeDots
                            color="orange"
                            height={25}
                            width={25}
                            type="ThreeDots"
                          />
                        }
                      </label>
                      <CurrencyFormat format="#####-###" mask="_"
                        placeholder="#####-###"
                        className="form-control"
                        value={data.cep ? data.cep : ''}
                        onValueChange={(values) => {
                          const { value } = values
                          if (value.length === 8) {
                            getCEP(value)
                          }
                          setData({ ...data, cep: value })
                        }}
                      />
                    </div>
                    {/* endereço */}
                    <div className="form-group">
                      <label className="text-label">Endereço</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        disabled
                        placeholder="Endereço"
                        value={data.address}
                        onChange={(e) => setData({ ...data, address: e.target.value })}
                      />
                    </div>
                    {/* nro */}
                    <div className="form-group">
                      <label className="text-label">Número</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Número"
                        value={data.number}
                        onChange={(e) => setData({ ...data, number: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

                <div className="row-forms">
                  <div className="grid">
                    {/* complemento */}
                    <div className="form-group">
                      <label className="text-label">Complemento</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="Complemento"
                        value={data.complement}
                        onChange={(e) => setData({ ...data, complement: e.target.value })}
                      />
                    </div>
                    {/* bairro */}
                    <div className="form-group">
                      <label className="text-label">Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        disabled
                        placeholder="Bairro"
                        value={data.district}
                        onChange={(e) => setData({ ...data, district: e.target.value })}
                      />
                    </div>
                    {/* cidade */}
                    <div className="form-group">
                      <label className="text-label">Cidade</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        disabled
                        placeholder="Cidade"
                        value={data.city}
                        onChange={(e) => setData({ ...data, city: e.target.value })}
                      />
                    </div>
                    {/* estado */}
                    <div className="form-group">
                      <label className="text-label">Estado</label>
                      <select
                        className='form-control form-control-lg'
                        id='inlineFormCustomSelect'
                        value={data.state}
                        disabled
                      // onChange={(e) => setData({ ...data, state: e.target.value })}
                      >
                        <option >-- ESTADO --</option>
                        {
                          UF.map((value) => (
                            <option value={value[0]}>{value[1]}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row-forms">
                  <div className="grid">
                    {/* phone */}
                    <div className="form-group">
                      <label className="text-label">Telefone</label>
                      <CurrencyFormat format="(##) #####-####" mask="_"
                        placeholder="(99) 9999-9999"
                        className="form-control"
                        value={data.phone_number ? data.phone_number : ''}
                        onValueChange={(values) => {
                          const { formattedValue } = values
                          setData({ ...data, phone_number: formattedValue })
                        }}
                      />
                    </div>
                    {/* email */}
                    <div className="form-group">
                      <label className="text-label">E-Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="example@example.com"
                        required
                        value={data.mail ? data.mail : ''}
                        onChange={(e) => setData({ ...data, mail: e.target.value })}
                      />
                    </div>
                    {/* categoria */}
                    <div className="form-group" >
                      <label className="text-label">Categoria</label>
                      <select
                        className='form-control form-control-lg'
                        id='inlineFormCustomSelect'
                        defaultValue={'option'}
                        value={data.category ? data.category : ''}
                        onChange={(e) => setData({ ...data, category: e.target.value })}
                      >
                        <option value="">-- CATEGORIA --</option>
                        <option value="1">Oficinas Mecânicas</option>
                        <option value="2">Postos de Combustível</option>
                        <option value="3">Restaurantes</option>
                        <option value="4">Hotéis</option>
                        <option value="5">Serviços de Pneu</option>
                        <option value="6">Centro Automotivo</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* botao */}
                <div className="row-forms">
                  <div className="form-group" >
                    <button disabled={loader || isDisabled} onClick={() => edit()} style={{ width: '17%' }} type="button" className="btn btn-info">
                      {
                        loader
                          ?
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp;Processando...
                          </> : 'Editar'
                      }
                    </button>
                  </div>
                </div>
              </section>
            </div>

            <Rodal
              visible={showModal}
              animation="flip"
              customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
              showCloseButton={true}
              onClose={() => setShowModal(false)}
            >
              <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                <Close fontSize="large" style={{ cursor: 'pointer' }} />
              </div>
              <div className="modal-error">
                {
                  isError
                    ?
                    <div><i className="fas fa-times red fa-2x"></i></div>
                    :
                    <div><i className="far fa-check-circle green fa-2x"></i></div>
                }
                <div>{text}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                <div className="modal-error-buttons" />
              </div>
            </Rodal>

          </div>
        </div>
      </div>
    </div>

  )
}

