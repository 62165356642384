import axios from "axios"
import { getToken } from "./auth"
const URL = "https://services.etrix.app.br/api"

const api = axios.create({
  baseURL: URL
})

api.interceptors.request.use(async config => {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
api.defaults.headers.get['Content-Type'] = 'application/json'
api.defaults.headers.post['Content-Type'] = 'application/json'
api.defaults.headers.patch['Content-Type'] = 'application/json'

api.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
api.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
api.defaults.headers.patch['Access-Control-Allow-Origin'] = '*'

export default api
