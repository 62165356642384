import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [companies, setCompanies] = useState([])
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const fields = {
        name: '',
        CPF: '',
        email: '',
        password: '',
        company: '',
        profile: '',
    }
    const [inputs, setInputs] = useState(fields)
    const [loader, setLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showUserPartner, setShowUserPartner] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    const getCompanies = async () => {

        try {
            const res = await api.get('/company')

            if (res.data) {
                setCompanies(res.data)
            }
        } catch (error) {
            console.log("error =>", error)
        }
    }

    const getCompany = async (idCompany) => {
        setShowUserPartner(false)

        try {
            const res = await api.get('/company/' + idCompany)
            if (res.data && res.data.company_type == 2) {
                setShowUserPartner(true)
            }
        }
        catch (error) {
            console.log("error =>", error)
            setShowUserPartner(false)
        }
    }

    const create = async () => {

        setLoader(true)
        setIsError(false)

        try {

            const res = await api.post("/user", {
                name: inputs.name,
                cpf: inputs.CPF,
                email: inputs.email,
                password: inputs.password,
                id_company: inputs.company,
                id_profile: inputs.profile
            })

            setText(res.data.message)
            setInputs(fields)
            setShowModal(true)

        } catch (error) {

            setIsError(true)
            setShowModal(true)
            setText('Erro ao Cadastrar Usuário.' + error.response.data.message[0])

        }
        setLoader(false)

    }

    const closeModal = async () => {
        setShowModal(false)
        props.history.push('/list-users')
    }

    useEffect(() => { getCompanies() }, [])

    useEffect(() => {
        if (
            inputs.name !== '' &&
            inputs.CPF !== '' &&
            inputs.email !== '' &&
            inputs.password !== '' &&
            inputs.company !== '' &&
            inputs.profile !== ''
        ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
        console.log('inputs => ', inputs)
    }, [inputs])

    useEffect(() => {
        if (inputs.company !== '') {
            getCompany(inputs.company)
        }
    }, [inputs.company])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <button
                                onClick={() => { props.history.push('/list-users') }}
                                style={{ width: '150px', marginLeft: 10 }}
                                type="button" className="btn btn-info"
                            >
                                Voltar
                            </button>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ height: '95%' }}>

                                <label className="text-label-title">
                                    <i className="fas fa-user-plus" />
                                    &nbsp;Criação de Usuários
                                </label>

                                <div className="separator" />
                                {/* linha */}
                                <div
                                    className="row-forms"
                                    style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                >
                                    {/* nome */}
                                    <div className="form-group">
                                        <label className="text-label">Nome*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: 700 }}
                                            placeholder="Nome..."
                                            value={inputs.name}
                                            onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                        />
                                    </div>
                                    {/* cpf */}
                                    <div className="form-group">
                                        <label className="text-label">CPF</label>
                                        <CurrencyFormat format="###.###.###-##" mask="_"
                                            placeholder="CPF..."
                                            style={{ width: 300 }}
                                            value={inputs.CPF}
                                            className="form-control"
                                            onValueChange={(values) => {
                                                const { formattedValue } = values
                                                setInputs({ ...inputs, CPF: formattedValue })
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* linha */}
                                <div
                                    className="row-forms"
                                    style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                >
                                    {/* email */}
                                    <div className="form-group">
                                        <label className="text-label">E-Mail</label>
                                        <input
                                            type="email"
                                            autoComplete="off"
                                            name="new-email"
                                            id="new-email"
                                            value={inputs.email}
                                            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                                            style={{ width: 500 }}
                                            className="form-control"
                                            aria-describedby="inputGroupPrepend2"
                                            placeholder="example@example.com"
                                            required
                                        />
                                    </div>
                                    {/* password */}
                                    <div className="form-group">
                                        <label className="text-label">Senha</label>
                                        <input
                                            type="password"
                                            style={{ width: 500 }}
                                            value={inputs.password}
                                            onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                            className="form-control"
                                            placeholder="Senha..."
                                            required
                                        />
                                    </div>
                                </div>
                                {/* linha */}
                                <div
                                    className="row-forms"
                                    style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                                >
                                    {/* empresa */}
                                    <div className="form-group">
                                        <label className="text-label">Empresa</label>
                                        <select
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            defaultValue={'option'}
                                            value={inputs.company}
                                            style={{ width: 500 }}
                                            onChange={(e) => setInputs({ ...inputs, company: e.target.value })}
                                        >
                                            <option value="">SELECIONE</option>
                                            {
                                                companies.length > 0 &&
                                                companies.map((company, index) =>
                                                    <option key={index} value={company.id}>
                                                        {company.trading_name}
                                                    </option>

                                                )
                                            }
                                        </select>
                                    </div>
                                    {/* perfil */}
                                    <div className="form-group">
                                        <label className="text-label">Perfil</label>
                                        <select
                                            defaultValue={'option'}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            value={inputs.profile}
                                            style={{ width: 500 }}
                                            onChange={(e) => setInputs({ ...inputs, profile: e.target.value })}
                                        >
                                            <option value="" >PERFIL</option>
                                            <option value='2'>Administrador</option>
                                            <option value='3'>Usuário</option>
                                            {
                                                showUserPartner &&
                                                <option value='4'>Parceiro Usuário</option>
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* botao */}
                                <div className="row-forms" style={{ flexDirection: 'row', display: 'contents' }}>
                                    <div className="form-group" >
                                        <button
                                            disabled={loader || isDisabled}
                                            onClick={() => create()}
                                            style={{ width: '17%' }}
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            {
                                                loader
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;Processando...
                                                    </> : 'Criar'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Rodal
                            visible={showModal}
                            animation="flip"
                            customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                            showCloseButton={true}
                            onClose={() => closeModal()}
                        >
                            <div className="close-rodal" onClick={() => closeModal()}>
                                <Close fontSize="large" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="modal-error">
                                {
                                    isError
                                        ?
                                        <div><i className="fas fa-times red fa-2x"></i></div>
                                        :
                                        <div><i className="far fa-check-circle green fa-2x"></i></div>
                                }
                                <div>{text}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                <div className="modal-error-buttons" />
                            </div>
                        </Rodal>
                    </div>
                </div>
            </div>
        </div>

    )
}

