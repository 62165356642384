import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Row } from "react-bootstrap"
import { Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'
import { getIdProfile } from "../../../../services/auth"

export default function App(props) {
    Moment.locale('pt')

    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        button: false,
        grid: false,
    })
    const [wallet, setWallet] = useState(0)
    const [returnedBalance, setReturnedBalance] = useState(0)
    const [returnHistory, setReturnHistory] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalResp, setShowModalResp] = useState(false)
    const fields = {
        idUser: '',
        value: 0,
        description: ''
    }
    const [inputs, setInputs] = useState(fields)
    const [text, setText] = useState('')
    const [icon, setIcon] = useState('far fa-check-circle green fa-2x')
    // variaveis de paginação
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentData = returnHistory.slice(indexOfFirstPost, indexOfLastPost);
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const ID_PROFILE = getIdProfile()

    const getBalance = async () => {
        setIcon('far fa-check-circle green fa-2x')
        setLoaders({ ...loaders, button: true })

        getWallet()
        getChargeback()

        setLoaders({ ...loaders, button: false })
    }

    // busca o valor total na carteira
    const getWallet = async () => {
        setLoaders({ ...loaders, grid: true })

        try {
            const res = await api.get(`/get-balance`)

            if (res.data) {
                setWallet(res.data)
            }
        } catch (err) {
            console.log("error =>", err)
        }
        setLoaders({ ...loaders, grid: false })
    }

    // busca o valor total retornado
    const getChargeback = async () => {
        setLoaders({ ...loaders, grid: true })

        try {
            const res = await api.get(`/chargeback`)

            if (res.data) {
                setReturnedBalance(res.data['balance'])
                setReturnHistory(res.data['extract'])
            }
        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, grid: false })
    }

    // retorna valor de saldo do PC para o MASTER
    const returnBalanceToMaster = async () => {
        setLoaders({ ...loaders, credits: true })

        try {
            const res = await api.post('/wallet-checkout', {
                value: inputs.value,
                description: "Devolução"
            })
            if (res) {
                setIcon('far fa-check-circle green fa-2x')
                setText('Créditos devolvidos com sucesso')
            }
        } catch (err) {
            setIcon('fas fa-times error fa-2x')
            setText(err.response.data.Creditos)
        }
        setLoaders({ ...loaders, users: false })
        setShowModal(false)
        setShowModalResp(true)
        getChargeback()
        getWallet()
    }

    // retorna valor de saldo do PCU para o PC
    const returnBalanceToPc = async () => {
        try {
            const res = await api.post('/wallet-entry-driver', {
                return: inputs.value,
                id_user: inputs.idUser
            })
            if (res) {
                setText('Créditos devolvidos com sucesso')
            }
        } catch (err) {
            setText(err.response.data.Creditos)
        }
        setShowModal(false)
        setShowModalResp(true)
    }

    // busca as informações do parceiro usuario
    const getDriverInfo = async () => {
        try {
            const response = await api.get(`/driver`)
            setInputs({
                ...inputs, idUser: response.data[0].id
            })
        } catch (err) {
            console.log(err)
        }
    }

    function handleCancel() {
        setShowModal(false);
        getBalance()
    }

    useEffect(() => {
        getBalance()
        getDriverInfo()
    }, [])

    useEffect(() => {
        if (!showModalResp) {
            getChargeback()
            getWallet()
        }
    }, [showModalResp])

    useEffect(() => { console.log('currentData', currentData) }, [currentData])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />

            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">

                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        {/* valores */}
                        <div className="menu-center">
                            <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {
                                        ID_PROFILE != 4 ?
                                            <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                                <label
                                                    style={{ margin: 0, display: 'flex', alignSelf: 'center', width: '100%', justifyContent: 'center' }}
                                                    className="text-label"
                                                >
                                                    Total Devolvido
                                                </label>
                                                <input
                                                    type="text"
                                                    value={`R$ ${utils.formatMoneyBRL(returnedBalance)}`}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                    disabled
                                                />
                                            </div>
                                            : <></>
                                    }

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label style={{ margin: 0, display: 'flex', alignSelf: 'center', width: '100%', justifyContent: 'center' }}
                                            className="text-label">Saldo Atual</label>
                                        <input
                                            type="text"
                                            value={`R$ ${utils.formatMoneyBRL(wallet)}`}
                                            className="form-control"
                                            aria-describedby="inputGroupPrepend2"
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* botao */}
                        <div className="menu-right">
                            <button
                                disabled={loaders.button}
                                onClick={() => getBalance()}
                                style={{ marginLeft: 10, width: '120px' }}
                                id="search-charge-back"
                                type="button"
                                className="btn btn-info"
                            >
                                {
                                    loaders.button
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <i className="fas fa-search fa-rotate-90"></i>
                                }
                            </button>
                            <button
                                onClick={() => setShowModal(true)}
                                style={{ marginLeft: 10, width: '130px' }}
                                type="button" className="btn btn-success"
                            >
                                <i className="fas fa-plus">&nbsp;&nbsp;</i>Devolver
                            </button>
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >

                                <div className="row-forms">
                                    {
                                        // ID_PROFILE != 4 ?
                                        loaders.grid
                                            ?
                                            <div>
                                                Consultando extratos...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white " />&nbsp;Devolução de créditos
                                                    </label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Data</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Descrição</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Saldo Devolvido</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentData.length > 0 &&
                                                            currentData.map((row, idx) =>
                                                                <tr role="row"
                                                                    key={idx}
                                                                >
                                                                    <td className="sorting_asc">
                                                                        {Moment(row.date).format('DD/MM/YYYY HH:mm:ss')}
                                                                    </td>
                                                                    <td className="sorting_asc">{row.description}</td>
                                                                    <td className="sorting_asc">
                                                                        R$ {row.value ? utils.formatMoneyBRL(row.value) : utils.formatMoneyBRL(0)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                                {/* paginação */}
                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={returnHistory.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                            </>
                                        // : <></>
                                    }
                                </div>
                            </section>

                            {/* modal de devolução */}
                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{ width: '40%', height: 'auto', marginTop: '1%', bottom: 'auto', top: '50%', transform: 'translateY(-50%)' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}
                            >
                                <div className="close-rodal" onClick={() => setShowModal(false)}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">Devolução</div>

                                {/* valores e descrição */}
                                <div className="detal-modal">
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        {/* valores */}
                                        <Row style={{ marginBottom: '16px' }}>
                                            {/* valor a ser devolvido */}
                                            <Col>
                                                <label className="text-label">Valor</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={inputs.value}
                                                    onValueChange={(values) => {
                                                        const { floatValue } = values
                                                        setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                    }}
                                                />
                                            </Col>
                                            {/* saldo atual do usuario/pc */}
                                            <Col>
                                                <label className="text-label">Saldo Usuário</label>
                                                <CurrencyFormat style={{ color: '#4bc019' }}
                                                    disabled
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor..."
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={wallet}
                                                />
                                            </Col>
                                        </Row>
                                        {/* descrição */}
                                        <Row>
                                            <Col>
                                                <label className="text-label">Descrição</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    maxlength="300"
                                                    rows="5"
                                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                                >
                                                    {inputs.description}
                                                </textarea>
                                            </Col>
                                        </Row>
                                    </Container>

                                    {/* botões */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {
                                            ID_PROFILE == 4 ?
                                                <button
                                                    onClick={() => returnBalanceToPc()}
                                                    style={{ marginLeft: 10, width: '120px' }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    Devolver ao Parceiro
                                                </button>
                                                : <button
                                                    onClick={() => returnBalanceToMaster()}
                                                    disabled={inputs.value > wallet}
                                                    style={{ marginLeft: 10, width: '120px' }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    Devolver ao Master
                                                </button>
                                        }

                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/* moda de retorno da api */}
                            <Rodal
                                visible={showModalResp}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModalResp(false)}
                            >
                                <div className="close-rodal" onClick={() => { setShowModalResp(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    <div><i className={icon}></i></div>
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}