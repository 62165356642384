import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Loader from '../Panel/loader'
import Rodal from 'rodal'
import { FilterList, Close } from '@material-ui/icons'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        business: false,
        delete: false
    })

    const [data, setData] = useState([])
    const [dataDelete, setDataDelete] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [filter, setFilter] = useState({
        name: '',
        tax_code: false,
        type: false,
        active: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const getCompanies = async () => {
        setLoaders({ ...loaders, business: true })

        try {
            let filters = '?'

            if (filter.name) {
                filters += `name=${filter.name}&`
            }
            if (filter.tax_code) {
                filters += `tax_code=${filter.tax_code}&`
            }
            if (filter.type) {
                filters += `type=${filter.type}&`
            }
            if (filter.active) {
                filters += `active=${filter.active}&`
            }

            filters = filters.substring(filters[0], filters.length - 1)
            let res

            if (filters !== '?') {
                res = await api.get(`/company${filters}`)
            } else {
                res = await api.get(`/company`)
            }
            setData(res.data)
            setCurrentPage(1)
        } catch (error) {
            console.log("error =>", error)
        }

        setLoaders({ ...loaders, business: false })
    }

    const deleteBusiness = async () => {
        setLoaders({ ...loaders, delete: true })

        try {
            const response = await api.delete(`/company/${dataDelete.id}`)
            getCompanies()
            setShowModal(false)
        } catch (err) {
            console.log(err)
        }

        setLoaders({ ...loaders, delete: false })
    }

    const getEditCompany = async (data) => {
        let mdr_percent = data.mdr_percent + ' '
        let mdr_value = data.mdr_value + ' '
        data.mdr_percent = mdr_percent.replace('.', ',')
        data.mdr_percent = data.mdr_percent.replace(' ', '')
        data.mdr_value = mdr_value.replace('.', ',')
        data.mdr_value = data.mdr_value.replace(' ', '')
        // console.log(data)
        props.history.push('/edit-business', { data: data })
    }

    useEffect(() => { getCompanies() }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                />

                                <CurrencyFormat format="##.###.###/####-##" mask="_"
                                    placeholder="CNPJ"
                                    className="form-control"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.tax_code}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, tax_code: formattedValue })
                                    }}
                                />

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    onClick={(e) => setFilter({ ...filter, type: e.target.value })}
                                >
                                    <option value="">TODAS AS EMPRESAS</option>
                                    <option value='pc'>PARCEIROS COMERCIAIS</option>
                                    <option value='pf'>PARCEIROS FORNECEDORES</option>
                                </select>

                                <select
                                    defaultValue={'option'}
                                    className='form-control form-control-lg'
                                    id='inlineFormCustomSelect'
                                    style={{ width: '200px', height: '35px' }}
                                    onClick={(e) => setFilter({ ...filter, active: e.target.value })}
                                >
                                    <option value="">TODAS AS EMPRESAS</option>
                                    <option value='true'>APENAS ATIVAS</option>
                                    <option value='false'>APENAS INATIVAS</option>
                                </select>

                                <button disabled={loaders.business} onClick={() => getCompanies()} style={{
                                    width: '150px',
                                    marginLeft: 10
                                }} type="button" className="btn btn-info">

                                    {
                                        loaders.business
                                            ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;Processando...
                                            </>
                                            :
                                            'Pesquisar'

                                    }
                                </button>
                            </div>

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel">
                            {
                                loaders.business ?
                                    <div className="loader">
                                        <h5>
                                            <Loader />
                                            <br />
                                            Carregando Empresas...
                                        </h5>
                                    </div>
                                    :
                                    data.length > 0
                                        ?
                                        <section className="form-create-users" style={{ display: 'flex' }}>
                                            <label className="text-label-title"><i className="fas fa-building white"></i> &nbsp; Listagem de Empresas</label>
                                            {/* <div className="separator" /> */}
                                            <div className="row-forms" style={{ flexDirection: 'column' }}>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc">ID</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nome Fantasia</th>
                                                            <th className="sorting_asc">Tipo</th>
                                                            <th className="sorting_asc">Empresa Master</th>
                                                            <th className="sorting_asc">CNPJ</th>
                                                            <th className="sorting_asc">Estado</th>
                                                            <th className="sorting_asc">Ativo</th>
                                                            <th className="sorting_asc">Editar</th>
                                                            <th className="sorting_asc">Desativar</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentPosts.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">{row.name}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            row.company_type === 1 ? "Master" :
                                                                                row.company_type === 2 ? "Parceiro Comercial" :
                                                                                    row.company_type === 3 ? "Parceiro Fornecedor" :
                                                                                        "Não Identificado"
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {row.company !== null ? row.company.name : '---'}
                                                                    </td>
                                                                    <td className="sorting_asc">{row.tax_code}</td>
                                                                    <td className="sorting_asc">{row.state}</td>
                                                                    <td className="sorting_asc">{row.deleted_at ? "NÃO" : "SIM"}</td>
                                                                    <td className="sorting_asc">
                                                                        <div className="items">
                                                                            <i onClick={() => getEditCompany(row)}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="la la la-edit blue la-2x"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        row.deleted_at ?
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setDataDelete(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-check green la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td className="sorting_asc">
                                                                                <div className="items">
                                                                                    <i onClick={() => { setDataDelete(row); setShowModal(true) }}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="la la la-times red la-2x"
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                    }

                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />

                                            </div>

                                        </section>
                                        :
                                        <section className="form-create-users" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div><i className="fas fa-exclamation-circle yellow fa-3x"></i></div>
                                            <div><h3 className="alert">Sem dados para esta consulta....</h3></div>
                                        </section>

                            }
                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '60%', height: 'calc(55%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        dataDelete.deleted_at ?
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-check green la-2x" />
                                                Tem certeza que deseja REATIVAR a Empresa
                                                <br />
                                                <h4 className="green">{dataDelete.name}</h4> ?
                                            </> :
                                            <>
                                                <i style={{ cursor: 'pointer' }} className="la la la-trash red la-2x" />
                                                Tem certeza que deseja DESATIVAR essa Empresa?
                                                <br />
                                                <h4 className="red">{dataDelete.name}</h4>
                                            </>
                                    }

                                    <br />
                                    <button
                                        disabled={loaders.delete}
                                        onClick={() => deleteBusiness()}
                                        style={{ width: '25%' }}
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        {
                                            loaders.delete
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                dataDelete.deleted_at ? 'Sim, reativar' : 'Sim, desativar'

                                        }
                                    </button>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

