import React, { useState, useEffect } from 'react'
import api from '../../../../services/api'
import Moment from 'moment'
import Menu from '../Panel/menu'
import { FilterList, Close } from '@material-ui/icons'
import { Col, Container, Row, Dropdown } from "react-bootstrap"
// import CurrencyFormat from 'react-currency-format'
import Rodal from 'rodal'
import utils from '../../../../utils/utils'
import { getMasterId } from '../../../../services/auth'
import Pagination from '../../Pagination'
import './buy-items.scss'

export default function BuyItems(props) {
    Moment.locale('pt')
    const [toggleMenu, setToggleMenu] = useState(false)
    const [purchaseType, setPurchaseType] = useState('supplier')
    const [isShowFilter, setIsShowFilter] = useState(false)
    const [catalogList, setCatalogList] = useState([])
    const [itemList, setItemList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [itensList, setItensList] = useState([])
    const [showModalCatalog, setShowModalCatalog] = useState(false)
    const [showModalRespBuy, setShowModalRespBuy] = useState(false)
    const [idSelectedCategory, setIdSelectedCategory] = useState('')
    const [idSelectedSubCategory, setIdSelectedSubCategory] = useState('')
    const [categoriesList, setCategoriesList] = useState([])
    const [subCategoriesList, setSubCategoriesList] = useState([])
    const [iconClass, setIconClass] = useState("far fa-check-circle green fa-2x")
    // carrinho de compras, verificar seu funcionamento
    // const [bagItems, setBagItems] = useState([])
    const [text, setText] = useState('')
    const [isError, setIsError] = useState(false)

    const fields = {
        id: 0,
        name: '',
        description: '',
        response: '',
        status: null,
        value: 0,
        idSupplierSelected: ''
    }
    const [inputs, setInputs] = useState(fields)

    const [items, setItems] = useState([])
    const [showBuyModal, setShowBuyModal] = useState(false)
    const [showImageModal, setShowImageModal] = useState(false)

    const [priceTrix, setPriceTrix] = useState(0)
    const [priceMarket, setPriceMarket] = useState(0)
    const [descTrix, setDescTrix] = useState(0)

    // paginação catalogo visao PF
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPostCat = currentPage * postsPerPage;
    const indexOfFirstPostCat = indexOfLastPostCat - postsPerPage;
    const currentPostsCatalog = catalogList.slice(indexOfFirstPostCat, indexOfLastPostCat);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    // paginação catalogo visao PC
    const [currentPageItem, setCurrentPageItem] = useState(1);
    const indexOfLastPostItem = currentPageItem * postsPerPage;
    const indexOfFirstPostItem = indexOfLastPostItem - postsPerPage;
    const currentPostsItem = catalogList.slice(indexOfFirstPostItem, indexOfLastPostItem);
    const paginateItem = pageNumber => setCurrentPageItem(pageNumber);
    // paginação itens
    const [currentPageItensList, setCurrentPageItensList] = useState(1);
    const [postsPerPageItemList] = useState(12);
    const indexOfLastPostItemList = currentPageItensList * postsPerPageItemList;
    const indexOfFirstPostItemList = indexOfLastPostItemList - postsPerPageItemList;
    const currentPostsItensList = itensList.slice(indexOfFirstPostItemList, indexOfLastPostItemList);
    const paginateItensList = pageNumber => setCurrentPageItensList(pageNumber);
    //Modal senha para compra 
    const [viewModal, setViewModal] = useState(false)
    const [valuePassword, setValuePassword] = useState()

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )

    // busca a lista de fornecedores
    const getSuppliers = async () => {
        try {
            const res = await api.get('/company')
            console.log(`res =>`, res.data)
            setSupplierList(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
    }

    // busca catálogos por fornecedor
    const getCatalogsWithSupplier = async () => {
        try {
            const res = await api.get(`get-catalogs-with-supplier/${inputs.idSupplierSelected}`)
            console.log(`resCat =>`, res.data)
            setCatalogList(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
    }

    // busca itens por fornecedor
    const getAllItemsForSupplier = async () => {
        try {
            const masterId = await getMasterId()
            const res = await api.get(`/all-itens/${masterId}`)
            console.log(`resCat =>`, res.data)
            setItemList(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
    }

    // busca informações do catálogo selecionado
    const getSelectedCatalogInfo = async (idCatalog) => {
        try {
            const res = await api.get(`/get-catalog-available/${idCatalog}`)
            console.log(`get-catalog-available =>`, res.data)
            if (res.data.items) {
                setItensList(res.data.items)
            }
            setShowModalCatalog(true)
        } catch (error) {
            console.log("error =>", error)
        }
    }

    // busca a lista de categorias
    const getCategories = async () => {
        // setLoaders({ ...loaders, users: true })
        try {
            const response = await api.get(`/category?filter=category`)
            console.log('categories -> ', response.data)
            setCategoriesList(response.data)

        } catch (err) {
            console.log(err)
        }
        // setLoaders({ ...loaders, users: false })
    }

    // busca a lista de subcategorias
    const getSubCategories = async () => {
        try {
            const response = await api.get(`/category?filter=subcategory&id=${idSelectedCategory}`)
            console.log('getSubCategories -> ', response.data)
            setSubCategoriesList(response.data)

        } catch (err) {
            console.log(err)
        }
    }

    const viewBuyModal = async () => {
        let price_market = 0
        let price_trix = 0
        let price_desc = 0

        items.forEach(item => {
            price_market += (parseFloat(item.priceMarket) * item.quantity)
            price_trix += (parseFloat(item.priceTrix) * item.quantity)
            price_desc += ((parseFloat(item.priceMarket) - parseFloat(item.priceTrix)) * item.quantity)
        })
        setPriceMarket(price_market)
        setPriceTrix(price_trix)
        setDescTrix(price_desc)

        setShowModalCatalog(false)
        setShowBuyModal(true)
    }

    const closeModalResp = async () => {
        setShowModalRespBuy(false)
        props.history.push('/manage-purchases')
    }

    const buy = async () => {
        setViewModal(false)
        setShowModalCatalog(false)
        closeBuyModal()
        setIconClass("")
        setValuePassword('')

        let arrItems = []
        items.map(item => {
            arrItems.push({ id: item.id, quantity: item.quantity })
        })

        try {
            // const response = await api.post(`/verify-password-transaction`, { "transaction_pass": valuePassword })
            // const status = response.data.status

            // if (status != "approved") {
            //     setText('Senha incorreta.')
            //     setIconClass("fas fa-times red fa-2x")
            //     setIsError(true)
            //     return
            // }

            const res = await api.post(`/purchase-order`, { "items": arrItems })

            if (res.data.error) {
                setText(res.data.error)
                setIconClass("fas fa-times red fa-2x")
            } else {
                setText('Solicitação de compra realizada com sucesso.')
                setText(res.data.message)
                setIconClass("far fa-check-circle green fa-2x")
            }

        } catch (err) {
            setIconClass("fas fa-times red fa-2x")
            //setText('Não foi possível realizar a compra. Saldo insuficiente.')
            setText('Não foi possível realizar a compra. ' + err.response.data.message)
            setIsError(true)
        } finally {
            setShowModalRespBuy(true)
            setViewModal(false)
        }
    }

    // atualiza carrinho de compras
    function updateBag({ target }, idx, item) {
        const itensCopy = Array.from(items);
        itensCopy.splice(idx, 1, { id: item.id, quantity: target.value, priceMarket: item.market_price, priceTrix: item.trix_price, name: item.name });
        setItems(itensCopy);
    }

    // fecha a modal do catalogo
    function handleCancel() {
        setShowModalCatalog(false);
        setShowBuyModal(false)
    }

    function closeBuyModal() {
        setPriceMarket(0)
        setPriceTrix(0)
        setDescTrix(0)
        setShowBuyModal(false)
    }

    function closeRodal() {
        setViewModal(false)
        setValuePassword('')
    }

    // quando for escolhido um tipo, habilita a grid
    useEffect(() => {
        setIsShowFilter(false)
        setCategoriesList([])

        if (purchaseType !== '') {
            setIsShowFilter(true)
            getCategories()

            if (purchaseType === 'supplier') getSuppliers()
            if (purchaseType === 'item') getAllItemsForSupplier()
        }
    }, [purchaseType])

    // quando for selecionada uma categoria, busca as subcategorias dela
    useEffect(() => {
        setSubCategoriesList([])

        if (idSelectedCategory !== '') getSubCategories()
    }, [idSelectedCategory])

    // quando for selecionado um fornecedor, busca os catalogos dele
    useEffect(() => {
        setCatalogList([])
        if (inputs.idSupplierSelected !== '') {
            getCatalogsWithSupplier()
        }
    }, [inputs.idSupplierSelected])

    return (
        <>
            <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
                <Menu props={props} toggleMenu={toggleMenu} />
                <div className="container-panel">
                    <div className="body-container">

                        {/* barra de navegação */}
                        <nav className="menu-container">
                            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                                <div className="bar">
                                    <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                    <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                    <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                                </div>
                            </div>
                            <div className="menu-center">
                                <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                            <FilterList fontSize="default" className="blue" />
                                        </div>
                                    </div>
                                    <select
                                        onChange={(e) => { setPurchaseType(e.target.value) }}
                                        style={{ width: '15%' }}
                                        className="form-control"
                                    >
                                        <option value="">Busca por...</option>
                                        {/* <option value="item">Itens</option> */}
                                        <option value="supplier" selected>Fornecedor</option>
                                    </select>
                                </div>
                            </div>
                        </nav>

                        <div className="data">
                            <div className="panel" style={{ padding: '1%' }}>
                                {/* grid de filtros e lista de itens e catalogos */}
                                <section
                                    className="container-trix"
                                    style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}
                                >
                                    {/* grid de itens */}
                                    {
                                        isShowFilter ?
                                            <div className="row-forms">
                                                <div className="detal-modal">
                                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                                        {
                                                            purchaseType === 'item' ?
                                                                <>
                                                                    <Row style={{ marginBottom: '16px' }}>
                                                                        {/* categoria */}
                                                                        <Col>
                                                                            <label className="text-label">Categoria</label>
                                                                            <select
                                                                                className="form-control"
                                                                                value={idSelectedCategory}
                                                                                onChange={(e) => setIdSelectedCategory(e.target.value)}
                                                                            >
                                                                                <option value="">-- CATEGORIA -- </option>
                                                                                {
                                                                                    categoriesList.length > 0 &&
                                                                                    categoriesList.map((category) =>
                                                                                        <option value={category.id}>{category.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                        {/* subcategoria */}
                                                                        <Col>
                                                                            <label className="text-label">Sub-Categoria</label>
                                                                            <select
                                                                                className="form-control"
                                                                                value={idSelectedSubCategory}
                                                                                onChange={(e) => setIdSelectedSubCategory(e.target.value)}
                                                                            >
                                                                                <option value="">-- SUB-CATEGORIA -- </option>
                                                                                {
                                                                                    subCategoriesList.length > 0 &&
                                                                                    subCategoriesList.map((subcat) =>
                                                                                        <option value={subcat.id}>{subcat.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                    </Row>
                                                                </> :
                                                                <>
                                                                    <Row style={{ marginBottom: '16px', width: '50%' }}>
                                                                        {/* fornecedor */}
                                                                        <Col>
                                                                            <label className="text-label">Fornecedor</label>
                                                                            <select
                                                                                value={inputs.idSupplierSelected}
                                                                                className='form-control form-control-lg'
                                                                                onChange={(e) => setInputs({
                                                                                    ...inputs,
                                                                                    idSupplierSelected: e.target.value
                                                                                })}
                                                                            >
                                                                                <option value="">-- Fornecedor -- </option>
                                                                                {
                                                                                    supplierList.length > 0 ?
                                                                                        supplierList.map(row =>
                                                                                            <option value={row.id}>{row.id} - {row.name}</option>
                                                                                        )
                                                                                        :
                                                                                        <option>-- Caregando --</option>
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                        }

                                                        {/* lista de itens ou catalogos */}
                                                        <Row>
                                                            {
                                                                purchaseType === 'supplier' ?
                                                                    <>
                                                                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                                            <thead>
                                                                                <tr role='row'>
                                                                                    <th className="sorting_asc">ID</th>
                                                                                    <th className="sorting_asc">Nome</th>
                                                                                    <th className="sorting_asc">Nro. Itens</th>
                                                                                    <th className="sorting_asc">Detalhes do Catálogo</th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                {
                                                                                    catalogList.length > 0 ?
                                                                                        currentPostsCatalog.map((row, idx) =>
                                                                                            <tr role="row" key={idx}>
                                                                                                <td className="sorting_asc">
                                                                                                    {row.id}
                                                                                                </td>
                                                                                                <td className="sorting_asc">{row.description}</td>
                                                                                                <td className="sorting_asc">{row.total_itens}</td>
                                                                                                <td className="sorting_asc" style={{ width: '300px' }} >
                                                                                                    <Dropdown >
                                                                                                        <Dropdown.Toggle
                                                                                                            variant="success"
                                                                                                            className="light sharp i-false"
                                                                                                            style={{
                                                                                                                width: '150px',
                                                                                                                height: '20px'
                                                                                                            }}
                                                                                                        >
                                                                                                            {svg1}
                                                                                                        </Dropdown.Toggle>
                                                                                                        <Dropdown.Menu>
                                                                                                            <Dropdown.Item
                                                                                                                onClick={(e) => getSelectedCatalogInfo(row.id)}
                                                                                                            >
                                                                                                                <i className="la la-money green la-lg" /> Abrir Catálogo
                                                                                                            </Dropdown.Item>
                                                                                                        </Dropdown.Menu>
                                                                                                    </Dropdown>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                        : <></>}
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination
                                                                            postsPerPage={postsPerPage}
                                                                            totalPosts={catalogList.length}
                                                                            paginate={paginate}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                                            <thead>
                                                                                <tr role='row'>
                                                                                    <th className="sorting_asc">Produto</th>
                                                                                    <th className="sorting_asc">Descrição</th>
                                                                                    <th className="sorting_asc">Marca</th>
                                                                                    <th className="sorting_asc">Preço eTrix</th>
                                                                                    <th className="sorting_asc">Preço de Mercado</th>
                                                                                    <th className="sorting_asc">Categoria</th>
                                                                                    <th className="sorting_asc">Sub Categoria</th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                {
                                                                                    itemList.length > 0 ?
                                                                                        currentPostsItem.map((row, idx) =>
                                                                                            <tr role="row" key={idx}>
                                                                                                <td className="sorting_asc">{row.name}</td>
                                                                                                <td className="sorting_asc">{row.description}</td>
                                                                                                <td className="sorting_asc">{row.brand}</td>
                                                                                                <td className="sorting_asc">{row.trix_price}</td>
                                                                                                <td className="sorting_asc">{row.market_price}</td>
                                                                                                <td className="sorting_asc">{row.category.name}</td>
                                                                                                <td className="sorting_asc">{row.sub_category.name}</td>
                                                                                            </tr>
                                                                                        )
                                                                                        : <></>}
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination
                                                                            postsPerPage={postsPerPage}
                                                                            totalPosts={itemList.length}
                                                                            paginate={paginateItem}
                                                                        />
                                                                    </>
                                                            }

                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                </section>

                                {/* modal de  itens do catalogo */}
                                <Rodal
                                    visible={showModalCatalog}
                                    animation="slideLeft"
                                    customStyles={{
                                        width: '70%',
                                        height: 'auto',
                                        marginTop: '1%',
                                        bottom: 'auto',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                    showCloseButton={true}
                                    onClose={() => setShowModalCatalog(false)}
                                >
                                    <div className="close-rodal" onClick={() => setShowModalCatalog(false)}>
                                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div className="title">Itens do Catálogo</div>
                                    <div className="detal-modal">

                                        <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                            {/* filtros */}
                                            <Row style={{ marginBottom: '16px' }}>
                                                {/* categoria */}
                                                {/* <Col>
                                                    <label className="text-label">Categoria</label>
                                                    <select
                                                        className="form-control"
                                                        value={idSelectedCategory}
                                                        onChange={(e) => setIdSelectedCategory(e.target.value)}
                                                    >
                                                        <option value="">-- CATEGORIA -- </option>
                                                        {
                                                            categoriesList.length > 0 &&
                                                            categoriesList.map((category) =>
                                                                <option value={category.id}>{category.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </Col> */}
                                                {/* subcategoria */}
                                                {/* <Col>
                                                    <label className="text-label">Sub-Categoria</label>
                                                    <select
                                                        className="form-control"
                                                        value={idSelectedSubCategory}
                                                        onChange={(e) => setIdSelectedSubCategory(e.target.value)}
                                                    >
                                                        <option value="">-- SUB-CATEGORIA -- </option>
                                                        {
                                                            subCategoriesList.length > 0 &&
                                                            subCategoriesList.map((subcat) =>
                                                                <option value={subcat.id}>{subcat.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </Col> */}
                                            </Row>
                                            {/* grid de itens */}
                                            <Row>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            {/* <th className="sorting_asc"> ID </th> */}
                                                            <th className="sorting_asc">Nome</th>
                                                            <th className="sorting_asc">Categoria</th>
                                                            <th className="sorting_asc">Subcategoria</th>
                                                            <th className="sorting_asc">Descrição</th>
                                                            <th className="sorting_asc">Valor</th>
                                                            <th className="sorting_asc">Quantidade</th>
                                                            {/* <th className="sorting_asc"><i style={{ fontSize: '20px' }} class="far fa-image"></i></th> */}
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            // loaders.availableItens ?
                                                            //     <div>
                                                            //         Buscando Itens...
                                                            //         <ThreeDots
                                                            //             color="#0ccbec"
                                                            //             height={40}
                                                            //             width={40}
                                                            //             type="ThreeDots"
                                                            //         />
                                                            //     </div>
                                                            //     :
                                                            itensList.length > 0 ?
                                                                currentPostsItensList.map((row, idx) =>
                                                                    <tr role="row" key={idx}>
                                                                        {/* <td className="sorting_asc">{row.id}</td> */}
                                                                        <td className="sorting_asc">{row.name}</td>
                                                                        <td className="sorting_asc">{row.category ? row.category.name : ''}</td>
                                                                        <td className="sorting_asc">
                                                                            {row.sub_category ? row.sub_category.name : ''}
                                                                        </td>
                                                                        <td className="sorting_asc">{row.description}</td>
                                                                        <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                        <td className="sorting_asc">
                                                                            <input
                                                                                name={`value_${idx}`}
                                                                                type="number"
                                                                                style={{ border: '1px solid lightgray', paddingLeft: '5%' }}
                                                                                onChange={(event) => updateBag(event, idx, row)}
                                                                            />
                                                                        </td>
                                                                        {/* <td>
                                                                            <i className="fas fa-eye"
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => setShowImageModal(true)}
                                                                            >
                                                                            </i>
                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                                : <></>}
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    postsPerPage={postsPerPageItemList}
                                                    totalPosts={itemList.length}
                                                    paginate={paginateItensList}
                                                />
                                            </Row>
                                        </Container>
                                        {/* botoes */}
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <button
                                                // onClick={() => buy()}
                                                onClick={() => viewBuyModal()}
                                                style={{
                                                    marginLeft: 10,
                                                    width: '120px',
                                                    backgroundColor: '#5e72e4'
                                                }}
                                                type="button"
                                                className="btn btn-success"
                                            >
                                                Ver Carrinho
                                            </button>
                                            <button
                                                onClick={handleCancel}
                                                style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                                type="button"
                                                className="btn btn-danger"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </Rodal>

                                {/* modal resposta api de compra */}
                                <Rodal
                                    visible={showModalRespBuy}
                                    animation="flip"
                                    customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                    showCloseButton={true}
                                    onClose={() => closeModalResp()}

                                >
                                    <div className="close-rodal" onClick={() => { closeModalResp() }}>
                                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div className="modal-error">
                                        {
                                            isError
                                                ?
                                                <>
                                                    <div><i className={iconClass}></i></div>
                                                    <div> {text}</div>
                                                </>
                                                :
                                                <>
                                                    <div><i className={iconClass}></i></div>
                                                    <div><i className="fas fa-coins green white"></i> {text}</div>
                                                </>
                                        }

                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                        <div className="modal-error-buttons" />
                                    </div>
                                </Rodal>

                                {/* modal extrato de compra */}
                                <Rodal
                                    visible={showBuyModal}
                                    animation="flip"
                                    customStyles={{
                                        display: 'grid',
                                        flexDirection: 'col',
                                        width: '60%',
                                        height: 'calc(60%)',
                                        marginTop: '10%'
                                    }}
                                    showCloseButton={true}
                                    onClose={() => closeBuyModal()}

                                >
                                    <div className="close-rodal" onClick={() => closeBuyModal()}>
                                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div className="modal-error" style={{ marginLeft: '5%', alignItems: 'start' }}>
                                        <div style={{ display: 'flex' }}><i className="fa fa-shopping-cart green white" />
                                            &ensp;Total da Compra (mercado):&ensp;
                                            <p style={{ color: 'orange', lineHeight: 1.5 }}>R$ {utils.formatMoneyBRL(priceMarket)}</p>
                                        </div>
                                        <div style={{ display: 'flex' }}><i className="fa fa-shopping-cart green white" />
                                            &ensp;Total da Compra (eTrix):&ensp;
                                            <p style={{ color: 'green', lineHeight: 1.5 }}>R$ {utils.formatMoneyBRL(priceTrix)}</p>
                                        </div>
                                        <div style={{ display: 'flex' }}><i className="fa fa-shopping-cart green white" />
                                            &ensp;Total de Desconto:&ensp;
                                            <p style={{ color: 'orange', lineHeight: 1.5 }}>R$ {utils.formatMoneyBRL(descTrix)}</p>
                                        </div>
                                    </div>
                                    <div style={{ alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                            <thead>
                                                <tr role='row'>
                                                    <th className="sorting_asc">Produto</th>
                                                    <th className="sorting_asc">Quantidade</th>
                                                    <th className="sorting_asc">Preço eTrix</th>
                                                    <th className="sorting_asc">Preço Mercado</th>
                                                    <th className="sorting_asc">Desconto R$</th>
                                                    <th className="sorting_asc">Total R$</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    items.length > 0 &&
                                                    items.map((item, idx) =>
                                                        <tr role="row" key={idx}>
                                                            <td className="sorting_asc">{item.name}</td>
                                                            <td className="sorting_asc">{item.quantity} unids.</td>
                                                            <td className="sorting_asc">R$ {utils.formatMoneyBRL(item.priceTrix)}</td>
                                                            <td className="sorting_asc">R$ {utils.formatMoneyBRL(item.priceMarket)}</td>
                                                            <td className="sorting_asc">R$ {utils.formatMoneyBRL(item.priceMarket - item.priceTrix)}</td>
                                                            <td className="sorting_asc">R$ {utils.formatMoneyBRL(item.priceTrix * item.quantity)}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* botoes */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => buy(true)}
                                            style={{
                                                marginLeft: 10,
                                                width: '120px',
                                                backgroundColor: '#5e72e4'
                                            }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Comprar
                                        </button>
                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Voltar
                                        </button>
                                    </div>
                                </Rodal>

                                {/* modal imagem produto */}
                                <Rodal
                                    visible={showImageModal}
                                    animation="flip"
                                    customStyles={{
                                        width: '60%',
                                        height: 'calc(60%)',
                                        marginTop: '10%'
                                    }}
                                    showCloseButton={true}


                                >
                                    <div className="close-rodal" onClick={() => setShowImageModal(false)}>
                                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                    </div>

                                </Rodal>
                                {/* Modal senha após tentativa compra */}
                                <Rodal
                                    visible={viewModal}
                                    animation="slideLeft"
                                    customStyles={{
                                        width: '25%',
                                        height: '255px',
                                        bottom: 'auto',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    }}
                                >

                                    {/* >Botão fechar Rodal */}
                                    <div className="close-rodal" onClick={() => closeRodal()}>
                                        <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                    </div>

                                    {/* Container modal contra senha */}
                                    <div className="modal-pass-transaction">
                                        <Container className="card">
                                            <div>
                                                <Row>
                                                    <Col className="descricao">
                                                        <p>Digite sua senha:</p>
                                                        <p>Ou vá até o menu: Usuários ➡️ Gerenciar➡️ Editar➡️ Senha para transações</p>
                                                        <p></p>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            required
                                                            value={valuePassword}
                                                            onChange={(e) => setValuePassword(e.target.value)}
                                                            placeholder="Digite sua senha..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Container>

                                    </div>

                                    {/* botões */}
                                    <div className="buttons-rodal">
                                        <button
                                            onClick={() => buy()}
                                            style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px', }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Verificar
                                        </button>
                                        <button
                                            onClick={closeRodal}
                                            style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Rodal>

                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}