import React from "react";
import ReactDOM from "react-dom";
import App from "./routes";
import { BrowserRouter } from 'react-router-dom'

// import reportWebVitals from "./reportWebVitals";
// import SimpleReactLightbox from "simple-react-lightbox";

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'))