import React, { useState } from "react"
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { isAuthenticated } from "./services/auth"
import "./css/style.css"
import Login from './jsx/components/paginas/Login/Login'
import Panel from './jsx/components/paginas/Panel/panel'
import Dashboards from './jsx/components/paginas/Panel/dashboards'
import CreateUsers from './jsx/components/paginas/Users/create-users'
import EditUsers from './jsx/components/paginas/Users/edit-users'
import CreateBusiness from './jsx/components/paginas/Business/create-business'
import ListUsers from './jsx/components/paginas/Users/list-users'
import ListBusiness from './jsx/components/paginas/Business/list-business'
import EditBusiness from './jsx/components/paginas/Business/edit-business'
import ClosingLotes from './jsx/components/paginas/Lots/closing-lots'
import PaymentsLots from './jsx/components/paginas/Lots/payments-lots'
import CreditsGenerator from './jsx/components/paginas/Credits/credits-generator'
import CreditsApproval from './jsx/components/paginas/Credits/credit-approval'
import OpenBudgets from './jsx/components/paginas/Budgets/open-budgets'
import ManagerBudgets from './jsx/components/paginas/Budgets/manager-budgets'
import ReportMaster from './jsx/components/paginas/reports/report-master'
import ReportPartner from './jsx/components/paginas/reports/report-master-partner'
import ReportSupplier from './jsx/components/paginas/reports/report-master-supplier'
import ReportPartnerCommercial from './jsx/components/paginas/reports/report-partner-commercial'
import ReportPartnerSupplier from './jsx/components/paginas/reports/report-partner-supplier'
import ManageItems from './jsx/components/paginas/ItemCatalog/manage-items'
import ApproveItems from './jsx/components/paginas/ItemCatalog/approve-items'
import Catalog from './jsx/components/paginas/ItemCatalog/catalog';
import CreditReturn from './jsx/components/paginas/Credits/credit-return';
import CreditManagement from './jsx/components/paginas/Credits/credit-management';
import CreditRemove from './jsx/components/paginas/Credits/credit-remove'

// import ListItens from './jsx/components/paginas/Itens/list-itens'
import ListCatalogs from './jsx/components/paginas/Catalog/list-catalogs'
// import ApproveItens from './jsx/components/paginas/Itens/approve-itens'
import ManagePurchases from "./jsx/components/paginas/Pdv/manage-purchases"
import BuyItems from "./jsx/components/paginas/Pdv/buy-items"

import ManageSales from './jsx/components/paginas/Pdv/manage-sales'
import ListCategories from './jsx/components/paginas/Categories/list-categories'
import EditCategories from './jsx/components/paginas/Categories/edit-categories'
import CreateCategories from './jsx/components/paginas/Categories/create-categories'

import FinancialExtract from './jsx/components/paginas/reports/financial-extract'

export default function Routes() {

  const [isAuth, setIsAuth] = useState(true)

  // console.log('isAuthenticated =>', isAuthenticated())
  // console.log('TOKEN =>', localStorage.getItem('TOKEN_KEY'))

  // useEffect(() => {

  //   console.log('isAuthenticated => ', isAuthenticated())

  // }, [isAuthenticated()])

  return (
    <BrowserRouter>
      <Switch>
        {
          isAuth
            ?
            <PrivateRoutes />
            :
            <Route exact path="/login" component={Login} />
        }
      </Switch>
    </BrowserRouter>
  )

}

export function PrivateRoutes(props) {

  // isAuthenticated()

  console.log(`isAuthenticated =>`, isAuthenticated())

  return (
    <>
      <Route exact path="/panel" component={Panel} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/dashboards" component={Dashboards} />
      <Route exact path="/create-users" component={CreateUsers} />
      <Route exact path="/edit-users" component={EditUsers} />
      <Route exact path="/create-business" component={CreateBusiness} />
      <Route exact path="/list-users" component={ListUsers} />
      <Route exact path="/list-business" component={ListBusiness} />
      <Route exact path="/edit-business" component={EditBusiness} />
      <Route exact path="/closing-lots" component={ClosingLotes} />
      <Route exact path="/payments-lots" component={PaymentsLots} />

      <Route exact path="/credits-generator" component={CreditsGenerator} />
      <Route exact path="/credit-approval" component={CreditsApproval} />
      <Route exact path="/credit-budgets" component={CreditsApproval} />
      <Route exact path="/credit-return" component={CreditReturn} />
      <Route exact path="/credit-management" component={CreditManagement} />
      <Route exact path="/credit-remove" component={CreditRemove} />

      <Route exact path="/open-budgets" component={OpenBudgets} />
      <Route exact path="/manager-budgets" component={ManagerBudgets} />
      <Route exact path="/report-master" component={ReportMaster} />
      <Route exact path="/report-master-partner" component={ReportPartner} />
      <Route exact path="/report-master-supplier" component={ReportSupplier} />
      <Route exact path="/report-partner-commercial" component={ReportPartnerCommercial} />
      <Route exact path="/report-partner-supplier" component={ReportPartnerSupplier} />

      {/* <Route exact path="/list-itens" component={ListItens} /> */}
      <Route exact path="/list-catalogs" component={ListCatalogs} />
      {/* <Route exact path="/approve-itens" component={ApproveItens} /> */}
      <Route exact path="/manage-purchases" component={ManagePurchases} />
      <Route exact path="/buy-itens" component={BuyItems} />
      <Route exact path="/manage-sales" component={ManageSales} />
      <Route exact path="/list-categories" component={ListCategories} />
      <Route exact path="/edit-categories" component={EditCategories} />
      <Route exact path="/create-categories" component={CreateCategories} />

      <Route exact path="/manage-items" component={ManageItems} />
      <Route exact path="/approve-items" component={ApproveItems} />
      <Route exact path="/catalog" component={Catalog} />

      <Route exact path="/financial-extract" component={FinancialExtract} />

      <Route exact path="/" component={Login} />
    </>
  )
}
