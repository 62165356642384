import React, { useEffect, useState } from 'react'
import Menu from '../Panel/menu'
import { DateRange } from '@material-ui/icons'
// import Loader from '../Panel/loader'
import utils from '../../../../utils/utils.js'
import { Container, Row } from "react-bootstrap"
import { Close } from '@material-ui/icons'
import api from '../../../../services/api'
// import { useMediaQuery } from 'react-responsive'
import ThreeDots from 'react-loader-spinner'
import Moment from 'moment'
import Rodal from 'rodal'

export default function FinancialExtract(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [dateIni, setDateIni] = useState(utils.getDateBefore(365))
  const [dateEnd, setDateEnd] = useState(utils.getDate())
  const [loader, setLoader] = useState(false)
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })

  const [loaders, setLoaders] = useState(false)
  const [extractData, setExtractData] = useState([])
  const [wallet, setWallet] = useState([])
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  let xxx = []

  const getData = async () => {
    setLoaders(true)
    setExtractData([])

    try {
      let dataIni = Moment(dateIni).format('YYYY-MM-DD')
      const res = await api.get(`/financial-extract?start_period=${dataIni}&end_period=${dateEnd}`)
      console.log(`financial-extract res =>`, res.data)
      setExtractData(res.data.extract)
      // setTotalWallet(res.data.balance)
    } catch (error) {
      console.log("error =>", error)
    }
    setLoaders(false)
  }

  // busca o valor total na carteira
  const getWallet = async () => {

    try {
      const res = await api.get(`/get-balance`)

      if (res.data) {
        setWallet(res.data)
      }
    } catch (err) {
      console.log("error =>", err)
    }
  }

  useEffect(() => {
    getData()
    getWallet()
  }, [])

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">

          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            {/* datas e botao de busca */}
            <div className="menu-center">
              {/* data ini */}
              <DateRange fontSize="large" />
              <input style={{ width: '15%', height: '35px' }}
                value={dateIni}
                id="dateIni"
                onChange={(e) => setDateIni(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              <i className="fas fa-sort tiffany fa-rotate-90"></i>&nbsp;&nbsp;
              {/* data fim */}
              <input style={{ width: '15%', height: '35px' }}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                type="date" className="form-control dateclass placeholderclass" format="dd/mm/yyyy" />
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              {/* botao */}
              <button type="button"
                className="btn btn-info"
                onClick={() => getData()}
                disabled={loader}
                style={{ width: '150px', height: '38px' }}
              >
                {
                  loader
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="fas fa-search fa-rotate-90"></i>
                }
              </button>
            </div>

            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>

          <div className="data">
            <div className="panel" style={{ padding: '1%' }}>
              {/* saldo */}
              <section className="container-trix" style={{ padding: '0.5%' }}>
                <div className="row-forms" style={{ height: '100%' }}>
                  <div className="filters">
                    <div className="grid">
                      <div className="form-group">
                        <label className="text-label green" style={{ marginLeft: '0px', marginBottom: '5px' }}>
                          Saldo Disponível + Saldo Reservado
                        </label>
                        <div className="separator-widget" />
                        <div className="balance"><i className="flaticon-381-network green " />
                          &nbsp; R$ {utils.formatMoneyBRL(wallet)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* grid de extratos */}
              <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >

                <div className="row-forms">
                  {
                    loaders
                      ?
                      <div>
                        Buscando Transações...
                        <ThreeDots
                          color="#0ccbec"
                          height={40}
                          width={40}
                          type="ThreeDots"
                        />
                      </div>
                      :
                      <>
                        {/* titulo */}
                        <div className="row-forms" style={{ flexDirection: 'row' }}>
                          <label className="text-label-title">
                            <i className="flaticon-381-network white " />&nbsp;Extrato Financeiro
                          </label>
                        </div>
                        {/* grid */}
                        <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                          <thead>
                            <tr role='row'>
                              <th className="sorting_asc">Data/Hora</th>
                              <th className="sorting_asc">Descrição</th>
                              <th className="sorting_asc">Tipo</th>
                              <th className="sorting_asc">Valor</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              extractData.map((row, idx) =>
                                <>
                                  <tr role="row" key={idx}>
                                    <td className="sorting_asc">{Moment(row.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td className="sorting_asc">{row.description}</td>
                                    {
                                      row.type === 'Saída' ?
                                        <td
                                          className="sorting_asc"
                                          style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                        >
                                          <div style={{ color: 'red' }}>
                                            &nbsp;PF&nbsp;
                                            <i className="fa fa-backward" /> (Gasto)&nbsp;
                                            <i className="fa fa-backward" />
                                            &nbsp;PC
                                          </div>
                                        </td>
                                        : row.type === 'Compra do Driver' ?
                                          <td
                                            className="sorting_asc"
                                            style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                          >
                                            <div style={{ color: 'red' }}>
                                              &nbsp;PF&nbsp;
                                              <i className="fa fa-backward" /> (Gasto)&nbsp;
                                              <i className="fa fa-backward" />
                                              &nbsp;Driver
                                            </div>
                                          </td>
                                          : row.type === 'Bloqueado pelo Master' ?
                                            <td
                                              className="sorting_asc"
                                              style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'center' }}
                                            >
                                              <div style={{ color: 'blue' }}>
                                                &nbsp;Master&nbsp;
                                                <i className="fa fa-step-forward" /> (Bloqueado)&nbsp;
                                                <i className="fa fa-step-forward" />
                                                &nbsp;PC
                                              </div>
                                            </td>
                                            : row.type === 'Estornado pelo Master' ?
                                              <td
                                                className="sorting_asc"
                                                style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                              >
                                                <div style={{ color: 'orange' }}>
                                                  &nbsp;Master&nbsp;
                                                  <i className="fa fa-step-backward" /> (Estornado)&nbsp;
                                                  <i className="fa fa-step-backward" />
                                                  &nbsp;PC
                                                </div>
                                              </td>
                                              : row.type === 'Estornado pelo PC' ?
                                                <td
                                                  className="sorting_asc"
                                                  style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                                >
                                                  <div style={{ color: 'orange' }}>
                                                    &nbsp;PC&nbsp;
                                                    <i className="fa fa-step-backward" /> (Estornado)&nbsp;
                                                    <i className="fa fa-step-backward" />
                                                    &nbsp;Driver
                                                  </div>
                                                </td>
                                                : row.type === 'Cedido' ?
                                                  <td
                                                    className="sorting_asc"
                                                    style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                                  >
                                                    <div style={{ color: 'orange' }}>
                                                      &nbsp;Usuário&nbsp;
                                                      <i className="fa fa-step-backward" /> (Cedido)&nbsp;
                                                      <i className="fa fa-step-backward" />
                                                      &nbsp;PC
                                                    </div>
                                                  </td>
                                                  : row.type === 'driverSendToCompany' ?
                                                    <td
                                                      className="sorting_asc"
                                                      style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'left' }}
                                                    >
                                                      <div style={{ color: 'green' }}>
                                                        &nbsp;Empresa<i className="fa fa-step-backward" />&nbsp;Usuário
                                                      </div>
                                                    </td>
                                                    : row.type === 'Devolvido' ?
                                                      <td
                                                        className="sorting_asc"
                                                        style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'left' }}
                                                      >
                                                        <div style={{ color: 'green' }}>
                                                          &nbsp;Usuário&nbsp;&nbsp;
                                                          <i className="fa fa-step-forward" />&nbsp;(Devolvido)&nbsp;
                                                          <i className="fa fa-step-forward" />&nbsp;PC
                                                        </div>
                                                      </td>
                                                      : row.type === 'Entrada' ?
                                                        <td
                                                          className="sorting_asc"
                                                          style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'left' }}
                                                        >
                                                          <div style={{ color: 'green' }}>
                                                            &nbsp;Master&nbsp;&nbsp;
                                                            <i className="fa fa-forward" />&nbsp;(enviado)&nbsp;
                                                            <i className="fa fa-forward" />&nbsp;PC
                                                          </div>
                                                        </td>
                                                        : row.type === 'Saldo Recebido pelo Driver' ?
                                                          <td
                                                            className="sorting_asc"
                                                            style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'left' }}
                                                          >
                                                            <div style={{ color: 'green' }}>
                                                              &nbsp;PC&nbsp;&nbsp;
                                                              <i className="fa fa-forward" />&nbsp;(enviado)&nbsp;
                                                              <i className="fa fa-forward" />&nbsp;Driver
                                                            </div>
                                                          </td>
                                                          : row.type === 'Devolvido pelo PC' ?
                                                            <td
                                                              className="sorting_asc"
                                                              style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                                            >
                                                              <div style={{ color: 'orange' }}>
                                                                &nbsp;Master&nbsp;&nbsp;
                                                                <i className="fa fa-step-backward" />&nbsp;(Devolvido)&nbsp;
                                                                <i className="fa fa-step-backward" />&nbsp;PC
                                                              </div>
                                                            </td>
                                                            : row.type === 'Devolvido pelo Driver' ?
                                                              <td
                                                                className="sorting_asc"
                                                                style={{ border: 'none', display: 'flex', height: 'auto', justifyContent: 'right' }}
                                                              >
                                                                <div style={{ color: 'orange' }}>
                                                                  &nbsp;PC&nbsp;&nbsp;
                                                                  <i className="fa fa-step-backward" />&nbsp;(Devolvido)&nbsp;
                                                                  <i className="fa fa-step-backward" />&nbsp;Driver
                                                                </div>
                                                              </td>
                                                              : <></>
                                    }
                                    <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.value)}</td>
                                  </tr>
                                </>
                              )
                            }

                          </tbody>
                        </table>
                      </>
                  }
                </div>
              </section>

              {/* modal 1 */}
              <Rodal
                visible={false}
                animation="slideLeft"
                customStyles={{
                  width: '70%',
                  height: 'auto',
                  marginTop: '1%',
                  bottom: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
                showCloseButton={true}
              // onClose={() => setShowModalItens(false)}
              >
                <div
                  className="close-rodal"
                // onClick={() => setShowModalItens(false)}
                >
                  <Close fontSize="large" style={{ cursor: 'pointer' }} />
                </div>
                <div className="title">XXXXXX</div>
                <div className="detal-modal">

                  <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                    <Row>
                      <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                        <thead>
                          <tr role='row'>
                            <th className="sorting_asc"> ID </th>
                            <th className="sorting_asc">Nome</th>
                            <th className="sorting_asc">Categoria</th>
                            <th className="sorting_asc">Subcategoria</th>
                            <th className="sorting_asc">Descrição</th>
                            <th className="sorting_asc">Valor</th>
                            <th className="sorting_asc">Incluido</th>
                          </tr>
                        </thead>

                        <tbody>
                          {
                            xxx.length > 0 &&
                            xxx.map((row, idx) =>
                              <tr role="row" key={idx}>
                                <td className="sorting_asc">{row.id}</td>
                                <td className="sorting_asc">{row.name}</td>
                                <td className="sorting_asc">
                                  {row.category ? row.category.name : ''}
                                </td>
                                <td className="sorting_asc">
                                  {row.sub_category ? row.sub_category.name : ''}
                                </td>
                                <td className="sorting_asc">{row.description}</td>
                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                <td className="sorting_asc" style={{ width: '100px' }} >
                                  {/* <input
                                                          type="checkbox"
                                                          checked={row.isIncluded}
                                                          onChange={(e) => handleChangeCheckbox(row.id, e.target.checked)} 
                                                      /> */}
                                </td>
                              </tr>
                            )
                          }

                        </tbody>

                      </table>
                    </Row>
                  </Container>

                  {/* botoes */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      // onClick={() => saveCatalog()} 
                      style={{ marginLeft: 10, width: '120px' }}
                      type="button"
                      className="btn btn-success"
                    >
                      Salvar
                    </button>
                    <button
                      // onClick={handleCancel} 
                      style={{ marginLeft: 10, width: '120px' }}
                      type="button"
                      className="btn btn-danger"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </Rodal>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}