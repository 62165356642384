import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import CurrencyFormat from 'react-currency-format'
import utils from '../../../../utils/utils.js'
import ReactStars from 'react-rating-stars-component'
import Pagination from '../../Pagination'

export default function App(props) {

    Moment.locale('pt')

    const [toggleMenu, setToggleMenu] = useState(false)

    const COMPANY_TYPE = localStorage.getItem('COMPANY_TYPE')
    const [loaders, setLoaders] = useState({
        suppliers: false,
        budgets: false,
        budgetsItems: false
    })

    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const [text, setText] = useState('')
    const fields = {
        id: 0,
        name: '',
        description: '',
        response: '',
        status: '',
        value: 0
    }
    const [inputs, setInputs] = useState(fields)
    const [showRating, setShowRating] = useState(false)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')
    const [infoRow, setInfoRow] = useState('')
    const [iconClass, setIconClass] = useState("far fa-check-circle green fa-2x")
    const [error, setError] = useState(false)
    const [budgeItems, setBudgeItems] = useState([])

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )
    const [filter, setFilter] = useState({
        name: '',
        tax_code: false,
        status: ''
    })
    // const [filter, setFilter] = useState('')
    // paginação
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.length > 0 ? data.slice(indexOfFirstPost, indexOfLastPost) : 0;
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const getBudgets = async () => {
        setLoaders({ ...loaders, budgets: true })
        setError(false)
        setData([])

        try {
            let filters = '?'

            if (filter.name) {
                filters += `name=${filter.name}&`
            }
            if (filter.tax_code) {
                filters += `cpf=${filter.cpf}&`
            }
            if (filter.status) {
                filters += `filter=${filter.status}&`
            }

            filters = filters.substring(filters[0], filters.length - 1)
            let res

            if (filters) {
                res = await api.get(`/budget${filters}`)
            } else {
                res = await api.get('/budget')
            }

            if (res.data) {
                setData(res.data)
            }

            if (res.data.error) {
                setError(true)
                setData([])
                setText(res.data.error)
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({ ...loaders, budgets: false })
    }

    const toggleStatus = async (row, status) => {
        setShowRating(false)
        setLoaders({ ...loaders, update: true })
        setIconClass("")

        let url = COMPANY_TYPE === '3' ? `budget/${row.id}` : `budget-approve/${row.id}`
        let body

        if (COMPANY_TYPE === '3') {
            body = { response: row.response, status: status, value: row.value }
        } else {
            if (rating !== 0) {
                body = { status: status, rating: rating, evaluation: comment }
            } else {
                body = { response: row.response ? row.response : '', status: status }
            }
        }

        try {
            const res = await api.put(url, body)

            if (res.data.error) {
                setText(res.data.error)
                setIconClass("fas fa-times red fa-2x")
            } else if (res.data.message) {
                setText(res.data.message)
                setIconClass("far fa-check-circle green fa-2x")
            }
            setShowModal(false)
            setShowStatus(true)
            setShowDetails(false)
            getBudgets()
        } catch (error) {
            console.log("error =>", error)
        } finally {
            setLoaders({ ...loaders, update: false })
            setInputs(fields)
            setComment('')
        }
    }

    const getDetails = (row) => {
        console.log('row', row)
        setInputs({
            ...inputs,
            id: row.id,
            name: `Orçamento #${row.id}`,
            response: row.response,
            description: row.request,
            value: row.value,
            status: row.status,
            id_selected_budget: row.id_selected_budget
        })
        setShowDetails(true)
    }

    const getStatus = (status) => {
        // visao PC
        if (COMPANY_TYPE === '2') {
            switch (status) {
                // orçamento recem aberto, só pode ser cancelado
                case 2:
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                            <button
                                disabled={!inputs.response}
                                onClick={() => toggleStatus(inputs, 0)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-warning"
                            >
                                Rejeitar
                            </button>
                        </div>
                    )
                case 3:
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                            <button
                                onClick={() => toggleStatus(inputs, 1)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-success"
                            >
                                Aprovar
                            </button>

                            <button
                                onClick={() => toggleStatus(inputs, 0)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-warning"
                            >
                                Rejeitar
                            </button>
                        </div>
                    )
                case 4:
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                            <button
                                onClick={() => handleRating(inputs)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-success"
                            >
                                Finalizar
                            </button>
                        </div>
                    )
                default:
                    return (<></>)

            }

        }
        if (COMPANY_TYPE === '3') {
            switch (status) {
                case 1:
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                            <button
                                disabled={!inputs.response}
                                onClick={() => toggleStatus(inputs, 4)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-success"
                            >
                                Concluir
                            </button>
                        </div>
                    )

                case 2:
                    return (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                                <button
                                    disabled={!inputs.response || !inputs.value}
                                    onClick={() => toggleStatus(inputs, 3)}
                                    style={{ marginLeft: 10, width: '150px' }}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    Aceitar
                                </button>

                                <button
                                    disabled={!inputs.response}
                                    onClick={() => toggleStatus(inputs, 0)}
                                    style={{ marginLeft: 10, width: '150px' }}
                                    type="button"
                                    className="btn btn-warning"
                                >
                                    Rejeitar
                                </button>
                            </div>
                        </>
                    )

                case 3:
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 10 }}>
                            <button
                                disabled={!inputs.response}
                                onClick={() => toggleStatus(inputs, 0)}
                                style={{ marginLeft: 10, width: '150px' }}
                                type="button"
                                className="btn btn-warning"
                            >
                                Rejeitar
                            </button>
                        </div>
                    )
                default:
                // case 4:
                //     return (
                //         < Dropdown.Item onClick={() => toggleStatus(row, 5)}>
                //             Finalizado <i className="la la-legal blue la-lg"></i>
                //         </Dropdown.Item >
                //     )
            }
        }
    }

    function handleRating(row) {
        setInfoRow(row)
        setShowRating(true);
    }

    const ratingChanged = (newRating) => {
        setRating(newRating)
    };

    function handleCancel() {
        setShowModal(false);
        getBudgets();
    }

    useEffect(() => {
        getBudgets()
        getBudgetItems()
    }, [])
    useEffect(() => { console.log('data', data) }, [data])

    const getBudgetItems = async () => {
        setLoaders({ ...loaders, budgetItems: true })
        setError(false)
        setBudgeItems([])

        try {

            let _res
            //res = await api.get('/budget')

            // if (res.data) {
            //setBudgeItems(res.data)
            let _budgeItem = [];
            _budgeItem.push(
                {
                    description: "",
                    unity: "",
                    quantity: "",
                    unity_price: 0,
                    total_price: 0,
                    hour: "",
                    group: "P"
                },
                {
                    description: "",
                    unity: "",
                    quantity: "",
                    unity_price: 0,
                    total_price: 0,
                    hour: "",
                    group: "P"
                },
                {
                    description: "",
                    unity: "",
                    quantity: "",
                    unity_price: 0,
                    total_price: 0,
                    hour: "",
                    group: "S"
                },
            );
            // setBudgeItems(_budgeItem)
            // }

            // if (res.data.error) {
            //     setError(true)
            //     setData([])
            //     setText(res.data.error)
            // }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({ ...loaders, budgetItems: false })
    }

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu">

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                {/* <input
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="NOME"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.name}
                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                /> */}

                                {/* <CurrencyFormat format="##.###.###/####-##" mask="_"
                                    placeholder="CNPJ"
                                    className="form-control"
                                    style={{ width: '200px', height: '35px', marginRight: 10 }}
                                    value={filter.tax_code}
                                    onValueChange={(values) => {
                                        const { formattedValue } = values
                                        setFilter({ ...filter, tax_code: formattedValue })
                                    }}
                                /> */}

                                <select
                                    onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                                    style={{ width: '15%' }}
                                    className="form-control"
                                >
                                    <option value="">TODOS</option>
                                    <option value="EA">EM ANDAMENTO</option>
                                    <option value="FI">FINALIZADOS</option>
                                    <option value="AV">AG. VENDEDOR</option>
                                    <option value="AC">AG. COMPRADOR</option>
                                </select>

                                <button
                                    disabled={loaders.suppliers}
                                    onClick={() => getBudgets()}
                                    style={{ marginLeft: 10, width: '120px' }}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.suppliers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            {/* grid de orçamentos */}
                            <section
                                className="container-trix"
                                style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }}
                            >

                                <div className="row-forms">
                                    {
                                        loaders.suppliers
                                            ?
                                            <div>
                                                Buscando Orçamentos...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Lista de Orçamentos</label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc"> ID </th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Orçamentos</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                            <th className="sorting_asc">Data - Hora</th>
                                                            <th className="sorting_asc">Status</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            error ?
                                                                <p>{text}</p>
                                                                :
                                                                currentPosts.length > 0 &&
                                                                currentPosts.map((row, idx) =>
                                                                    <tr role="row" key={idx}>
                                                                        <td className="sorting_asc">OS-{row.id}</td>
                                                                        <td style={{ textAlign: 'start' }} className="sorting_asc">{`Orçamento #${row.id}`}</td>
                                                                        <td>{row.company_partner ? row.company_partner.name : row.company_supplier.name}</td>
                                                                        <td className="sorting_asc">{Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                                        <td className="sorting_asc">
                                                                            {
                                                                                row.status === 0 ?
                                                                                    <div style={{ color: 'red' }}>Recusado <i className="la la-times-circle red la-lg"></i></div>
                                                                                    : row.status === 1 ?
                                                                                        <div style={{ color: 'green' }}>Comprador Aprovou <i className="la la-check-circle green la-lg"></i></div>
                                                                                        : row.status === 2 ?
                                                                                            <div style={{ color: 'orange' }}>Aguardando Vendedor <i className="la la-info-circle la-lg"></i></div>
                                                                                            : row.status === 3 ?
                                                                                                <div style={{ color: 'gray' }}>Aguardando Comprador <i className="la la-check-circle green la-lg"></i></div>
                                                                                                : row.status === 4 ?
                                                                                                    <div style={{ color: 'gray' }}>Produto Enviado/Pronto <i className="la la-check-circle blue la-lg"></i></div>
                                                                                                    : row.status === 5 ?
                                                                                                        <div style={{ color: 'gray' }}>Produto Entregue  <i className="la la-legal blue la-lg"></i></div>
                                                                                                        :
                                                                                                        <div>Não Identificado</div>
                                                                            }
                                                                        </td>
                                                                        <td className="sorting_asc" style={{ width: '300px' }} >
                                                                            <Dropdown >
                                                                                <Dropdown.Toggle
                                                                                    variant="success"
                                                                                    className="light sharp i-false"
                                                                                    style={{
                                                                                        width: '150px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                >
                                                                                    {svg1}
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item onClick={() => getDetails(row)}>
                                                                                        <i className="la la-info-circle gray la-lg" /> Detalhes
                                                                                    </Dropdown.Item>
                                                                                    {/* {getStatus(row)} */}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                />
                                            </>
                                    }
                                </div>
                            </section>

                            {/* Detalhamento */}
                            <Rodal
                                visible={showDetails}
                                animation="slideLeft"
                                customStyles={{ width: '60%', height: '80%', overflowY: 'scroll', marginTop: '3%' }}
                                showCloseButton={true}
                                onClose={() => setShowDetails(false)}>
                                <div className="close-rodal" onClick={() => { setShowDetails(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">DETALHAMENTO</div>
                                <div className="detal-modal">
                                    {/* nome orçamento */}
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label blue"> {inputs.name} </label>
                                        </div>
                                    </div>


                                    <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                        <div className="form-group">
                                            {
                                                budgeItems.map((budge) => (
                                                    <div className="row-forms" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                        <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                            <label className="text-label">Grupo</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Grupo"
                                                                value={budge === "P" ? 'Produto' : 'Serviço'}
                                                            />
                                                        </div>
                                                        <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                            <label className="text-label">Descrição</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Descrição"
                                                            // onChange={(e) => setBudgeItems({ ...budgeItems, description: e.target.value })}
                                                            />
                                                        </div>
                                                        {budge.group === "P" ?
                                                            <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                                <label className="text-label">Unidade</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Unidade"
                                                                />
                                                            </div>
                                                            :
                                                            <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                                <label className="text-label">Hora</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Hora"
                                                                />
                                                            </div>
                                                        }
                                                        <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                            <label className="text-label">Quantidade</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Quantidade"
                                                            />
                                                        </div>
                                                        <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                            <label className="text-label">Valor Unit.</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Valor Unit."
                                                            />
                                                        </div>
                                                        <div className="form-group" style={{ width: '20%', marginRight: 20 }}>
                                                            <label className="text-label">Valor Tot.</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Valor Tot."
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    {/* descrição */}
                                    <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                                        <label className="text-label">Descrição*</label>
                                        <textarea
                                            disabled
                                            type="text"
                                            className="form-control"
                                            required
                                            value={inputs.description}
                                        />
                                    </div>
                                    {
                                        inputs.id_selected_budget != null ?
                                            <>
                                                <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                                                    <label className="text-label">Orçamento Vinculado</label>
                                                    <select style={{ width: '50%' }} className="form-control" disabled>
                                                        <option value={inputs.id_selected_budget}>Orçamento #{inputs.id_selected_budget}</option>
                                                    </select>
                                                </div>
                                            </> : <></>
                                    }
                                    {/* resposta */}
                                    <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                                        <label className="text-label">Resposta</label>
                                        {
                                            inputs.status == 2 ?
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    value={inputs.response}
                                                    onChange={(e) => { setInputs({ ...inputs, response: e.target.value }) }}
                                                /> : inputs.status == 0 ?
                                                    <textarea
                                                        disabled
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        value={inputs.response}
                                                    />
                                                    : <textarea
                                                        disabled={inputs.response}
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        value={inputs.response}
                                                        onChange={(e) => { setInputs({ ...inputs, response: e.target.value }) }}
                                                    />
                                        }
                                    </div>
                                    {/* valor */}
                                    <br />
                                    <div className="row-forms" style={{ textAlign: 'initial', width: '100%' }}>
                                        <label className="text-label">Valor:</label>
                                        {
                                            COMPANY_TYPE === '3' && inputs.status == 2 ?
                                                <CurrencyFormat
                                                    style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor"
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={inputs.value || 0}
                                                    onValueChange={(values) => {
                                                        const { floatValue } = values
                                                        setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                    }}
                                                />
                                                : <CurrencyFormat
                                                    disabled
                                                    style={{ color: '#4bc019' }}
                                                    currency="BRL"
                                                    prefix='R$ '
                                                    placeholder="Valor"
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="form-control"
                                                    value={inputs.value || 0}
                                                />
                                        }
                                    </div>

                                    {/* botoes */}
                                    {getStatus(inputs.status)}
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModal}
                                animation="slideLeft"
                                customStyles={{ width: '60%', height: 'calc(80%)', marginTop: '7%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}>
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">DETALHAMENTO</div>
                                <div className="detal-modal">
                                    <div className="row-forms">
                                        <div className="form-group">
                                            <label className="text-label blue">-- {inputs.name} --</label>
                                        </div>
                                    </div>
                                    <div className="row-forms" style={{ textAlign: 'initial' }}>

                                        <label className="text-label">Descrição*</label>
                                        <textarea
                                            disabled
                                            type="text"
                                            className="form-control"
                                            required
                                            value={inputs.description}
                                        />
                                        <hr />
                                    </div>
                                    <br />
                                    {
                                        COMPANY_TYPE === '3' &&
                                        <>
                                            <div className="row-forms">
                                                <div className="form-group">
                                                    <label className="text-label">Resposta*</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        value={inputs.response}
                                                        onChange={(e) => setInputs({ ...inputs, response: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row-forms">
                                                <div className="form-group">
                                                    <label className="text-label">Total</label>
                                                    {
                                                        inputs.value !== null && inputs.value !== 0 ?
                                                            <CurrencyFormat style={{ color: '#4bc019' }}
                                                                currency="BRL"
                                                                prefix='R$ '
                                                                placeholder="Valor..."
                                                                thousandSeparator='.'
                                                                decimalSeparator=','
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className="form-control"
                                                                value={inputs.value}
                                                                onValueChange={(values) => {
                                                                    const { floatValue } = values
                                                                    setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                                }}
                                                            />
                                                            :
                                                            <CurrencyFormat style={{ color: '#4bc019' }}
                                                                currency="BRL"
                                                                prefix='R$ '
                                                                placeholder="Valor..."
                                                                thousandSeparator='.'
                                                                decimalSeparator=','
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className="form-control"
                                                                value={''}
                                                                onValueChange={(values) => {
                                                                    const { floatValue } = values
                                                                    setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                                                }}
                                                            />
                                                    }

                                                </div>
                                            </div>
                                            <div className="row-forms">
                                                <div className="form-group" >
                                                    <button
                                                        disabled={inputs.value === 0 || inputs.value === null ? true : false}
                                                        onClick={() => toggleStatus(inputs, 3)}
                                                        style={{ width: '40%' }} type="button" className="btn btn-info">

                                                        {
                                                            loaders.update
                                                                ?
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    &nbsp;Processando...
                                                                </>
                                                                :
                                                                'Aceitar'

                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showStatus}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowStatus(false)}
                            >
                                <div className="close-rodal" onClick={() => { setShowStatus(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    <div><i className={iconClass}></i></div>
                                    <div>{text}</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                            {/* avaliação */}
                            <Rodal
                                visible={showRating}
                                animation="slideLeft"
                                customStyles={{
                                    width: '30%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowRating(false)}>
                                <div className="close-rodal" onClick={() => setShowRating(false)}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="detal-modal">
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row>
                                            <Col>
                                                COMO VOCÊ AVALIA ESTA COMPRA?
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={40}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                    placeholder="Deixe seu comentário"
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => toggleStatus(infoRow, 5)}
                                            style={{ marginLeft: 10, width: '150px' }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Avaliar
                                        </button>
                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

