import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'

export default function App(props) {
    Moment.locale('pt')
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        catalogs: false,
        removeCatalog: false,
    })
    const [catalogs, setCatalogs] = useState([])
    const [titleButton, setTitleButton] = useState('')
    const [showModal, setShowModal] = useState({
        createCatalog: false,
        editCatalog: false,
        modalResp: false,
    })
    const fields = {
        description: '',
        active: 1,
        items: []
    }
    const [inputs, setInputs] = useState(fields)
    const [numberItems, setNumberItems] = useState(0);
    const [itemsCatalog, setItemsCatalog] = useState([])
    const [allItems, setAllItems] = useState([])
    const [text, setText] = useState('')
    const [isError, setIsError] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentData = catalogs.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )

    const getCatalogs = async () => {
        setLoaders({ ...loaders, catalogs: true })

        try {
            const res = await api.get('/catalog')
            setCatalogs(res.data)
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({ ...loaders, catalogs: false })
    }

    const getAllItens = async () => {
        setLoaders({ ...loaders, users: true })
        setAllItems([])

        try {
            const response = await api.get(`/item`)
            setAllItems(response.data)

        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, users: false })
    }

    const saveCatalog = async () => {
        setText('')
        setIsError(false)

        try {
            const res = await api.post('/catalog', {
                description: inputs.description,
                active: inputs.active,
                items: itemsCatalog
            })

            if (res.data) {
                setText('Catálogo cadastrado com sucesso')
                setInputs(fields)
            }
        } catch (err) {
            setIsError(true)
            setText('Não foi possivel cadastrar o Catálogo. Erro ' + err)
        } finally {
            setShowModal({ ...showModal, modalResp: true })
            setItemsCatalog([])
        }
    }

    const editCatalog = async () => {
        setText('')
        setIsError(false)

        try {
            let aux = []

            itemsCatalog.map((item) => {
                aux.push({ id: item.id })
            })

            const res = await api.put(`/catalog/${inputs.id}`, {
                description: inputs.description,
                active: inputs.active,
                items: aux
            })

            if (res.data) {
                setText('Catálogo cadastrado com sucesso')
            }
            setInputs(fields)
        } catch (err) {
            setIsError(true)
            setText('Não foi possivel cadastrar o Catálogo. Erro ' + err)
        } finally {
            setItemsCatalog([])
            setShowModal({ ...showModal, modalResp: true })
        }
    }

    function handleChangeCheckbox(idItem, isChecked) {
        if (isChecked) {
            setItemsCatalog([...itemsCatalog, { id: idItem }])
            setNumberItems(numberItems + 1);
        } else {
            let aux = []
            itemsCatalog.map((item) => {
                if (item.id != idItem) {
                    aux.push(item)
                }
            })
            setItemsCatalog(aux)
            if (numberItems > 0) {
                setNumberItems(numberItems - 1);
            }
        }
    }

    function handleChangeCheckboxEditCatalog(item, isChecked) {
        if (isChecked) {
            let isPresent = false

            itemsCatalog.map((itemCat) => {
                if (item.id == itemCat.id) { isPresent = true }
            })

            if (!isPresent) {
                item = { ...item, isIncluded: true }
                setItemsCatalog([...itemsCatalog, item])
                setNumberItems(numberItems + 1);
            }
        } else {
            let aux = []
            itemsCatalog.map((itemCat) => {
                if (itemCat.id != item.id) {
                    aux.push(itemCat)
                }
            })
            setItemsCatalog(aux)
            if (numberItems > 0) {
                setNumberItems(numberItems - 1);
            }
        }
    }

    function handleCancel() {
        setShowModal({
            createCatalog: false,
            editCatalog: false,
            modalResp: false,
        })
        setItemsCatalog([])
        getCatalogs();
    }

    const handleRemove = async (id) => {
        setIsError(false)

        try {
            const res = await api.delete(`/catalog/${id}`)
            if (res) {
                setText('Catálogo removido com sucesso')
            } else {
                setText('Não foi possível remover o catálogo')
            }
        } catch (err) {
            setIsError(true)
            setText('Não foi possivel remover o catálogo. Erro ' + err)
        } finally {
            setShowModal({ ...showModal, modalResp: true })
            setInputs(fields)
        }
    }

    const handleEdit = async (id) => {
        setInputs(fields)
        setAllItems([])
        setItemsCatalog([])

        try {
            const res = await api.get(`/catalog/${id}`)

            if (res.data) {
                setInputs({
                    id: id,
                    description: res.data.description,
                    active: res.data.active,
                })

                setItemsCatalog(res.data.items.map((item) => {
                    return { ...item, isIncluded: true }
                }))

                getAllItens()
            }

            setShowModal({ ...showModal, editCatalog: true })
        } catch (err) {
            setIsError(true)
            setText('Não foi possivel cadastrar o Catálogo. Erro ' + err)
        }
    }

    useEffect(() => {
        if (showModal.createCatalog) {
            getAllItens()
        } else {
            setAllItems([])
        }
    }, [showModal.createCatalog])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu" style={{ justifyContent: 'flex-start' }}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '45px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>

                                <button
                                    disabled={loaders.suppliers}
                                    onClick={() => getCatalogs()}
                                    style={{ marginLeft: 10, width: '120px', backgroundColor: '#5e72e4' }}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.suppliers
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            : <i className="fas fa-search fa-rotate-90" />
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <button
                                onClick={() => setShowModal({ ...showModal, createCatalog: true })}
                                style={{ marginLeft: 10, width: '185px', backgroundColor: '#5e72e4' }}
                                type="button"
                                className="btn btn-success"
                            >
                                <i className="fas fa-plus">&nbsp;&nbsp;</i>Criar Catálogo
                            </button>
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>

                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >
                                <div className="row-forms">
                                    {
                                        loaders.catalogs ?
                                            <div>
                                                Buscando Catálogos...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white " />
                                                        &nbsp;Gerenciar Catálogos
                                                    </label>
                                                </div>

                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc"> ID </th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Descrição</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nro. Itens</th>
                                                            {/* <th className="sorting_asc">Valor</th> */}
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentData.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td style={{ textAlign: 'start' }} className="sorting_asc">{row.description}</td>
                                                                    <td style={{ textAlign: 'center' }} className="sorting_asc">{row.total_itens}</td>
                                                                    <td className="sorting_asc" style={{ width: '300px' }} >
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => handleEdit(row.id)} >
                                                                                    <i className="la la-edit gray la-lg" /> Editar
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => handleRemove(row.id)} >
                                                                                    <i className="la la-times-circle gray la-lg" /> Remover
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>

                                                </table>
                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={catalogs.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                            </>
                                    }
                                </div>
                            </section>

                            <Rodal
                                visible={showModal.createCatalog}
                                animation="slideLeft"
                                customStyles={{
                                    width: '60%',
                                    height: '80%',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '48%',
                                    transform: 'translateY(-50%)',
                                    overflow: 'scroll'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowModal({ ...showModal, createCatalog: false })}
                            >
                                <div className="close-rodal" onClick={() => setShowModal({ ...showModal, createCatalog: false })}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">Criação do Catálogo</div>
                                <div className="detal-modal">

                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row style={{ marginBottom: '16px' }}>
                                            {/* descrição */}
                                            <Col>
                                                <label className="text-label">Descrição</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={inputs.description}
                                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* nro itens */}
                                            <Col>
                                                <label className="text-label">Nro. Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={numberItems}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* status */}
                                            <Col>
                                                <label className="text-label">Status</label>
                                                <select
                                                    value={inputs.active}
                                                    className='form-control form-control-lg'
                                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                                >
                                                    <option value="1">Ativo</option>
                                                    <option value="0">Inativo</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        {/* grid itens */}
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc"> ID </th>
                                                        <th className="sorting_asc">Nome</th>
                                                        <th className="sorting_asc">Categoria</th>
                                                        <th className="sorting_asc">Subcategoria</th>
                                                        <th className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor</th>
                                                        <th className="sorting_asc">Incluido</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        allItems.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                <td className="sorting_asc">{row.id}</td>
                                                                <td className="sorting_asc">{row.name}</td>
                                                                <td className="sorting_asc">
                                                                    {row.category ? row.category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">
                                                                    {row.sub_category ? row.sub_category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">{row.description}</td>
                                                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                <td className="sorting_asc" style={{ width: '100px' }} >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={row.isIncluded}
                                                                        onChange={(e) => handleChangeCheckbox(row.id, e.target.checked)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>

                                            </table>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => saveCatalog()}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#5e72e4' }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Criar
                                        </button>
                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModal.editCatalog}
                                animation="slideLeft"
                                customStyles={{
                                    width: '60%',
                                    height: '80%',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '48%',
                                    transform: 'translateY(-50%)',
                                    overflow: 'scroll'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowModal({ ...showModal, editCatalog: false })}
                            >
                                <div className="close-rodal" onClick={() => setShowModal({ ...showModal, editCatalog: false })}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="title">Criação do Catálogo</div>
                                <div className="detal-modal">

                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row style={{ marginBottom: '16px' }}>
                                            {/* descrição */}
                                            <Col>
                                                <label className="text-label">Descrição</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    disabled
                                                    value={inputs.description}
                                                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* nro itens */}
                                            <Col>
                                                <label className="text-label">Nro. Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={numberItems}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* status */}
                                            <Col>
                                                <label className="text-label">Status</label>
                                                <select
                                                    value={inputs.active}
                                                    className='form-control form-control-lg'
                                                    onChange={(e) => setInputs({ ...inputs, active: e.target.value })}
                                                >
                                                    <option value="">-- Status -- </option>
                                                    <option value="1">Ativo</option>
                                                    <option value="0">Inativo</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        {/* grid itens */}
                                        <div className="title">Itens do Catálogo</div>
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc"> ID </th>
                                                        <th className="sorting_asc">Nome</th>
                                                        <th className="sorting_asc">Categoria</th>
                                                        <th className="sorting_asc">Subcategoria</th>
                                                        <th className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor</th>
                                                        <th className="sorting_asc">Incluido</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        itemsCatalog.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                <td className="sorting_asc">{row.id}</td>
                                                                <td className="sorting_asc">{row.name}</td>
                                                                <td className="sorting_asc">
                                                                    {row.category ? row.category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">
                                                                    {row.sub_category ? row.sub_category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">{row.description}</td>
                                                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                <td className="sorting_asc" style={{ width: '100px' }} >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={row.isIncluded}
                                                                        onChange={(e) => handleChangeCheckboxEditCatalog(row, e.target.checked)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>

                                            </table>
                                        </Row>

                                        <div className="title">Todos os Itens</div>
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc"> ID </th>
                                                        <th className="sorting_asc">Nome</th>
                                                        <th className="sorting_asc">Categoria</th>
                                                        <th className="sorting_asc">Subcategoria</th>
                                                        <th className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor</th>
                                                        <th className="sorting_asc">Incluido</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        allItems.map((row, idx) =>
                                                            <tr role="row" key={idx}>
                                                                <td className="sorting_asc">{row.id}</td>
                                                                <td className="sorting_asc">{row.name}</td>
                                                                <td className="sorting_asc">
                                                                    {row.category ? row.category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">
                                                                    {row.sub_category ? row.sub_category.name : ''}
                                                                </td>
                                                                <td className="sorting_asc">{row.description}</td>
                                                                <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                <td className="sorting_asc" style={{ width: '100px' }} >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={row.isIncluded}
                                                                        onChange={(e) => handleChangeCheckboxEditCatalog(row, e.target.checked)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>

                                            </table>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            onClick={() => editCatalog()}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#5e72e4' }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Editar
                                        </button>
                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px', backgroundColor: '#ff9900' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            <Rodal
                                visible={showModal.modalResp}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => handleCancel()}

                            >
                                <div className="close-rodal" onClick={() => { handleCancel() }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError ?
                                            <>
                                                <div><i className="fas fa-times error fa-2x" /></div>
                                                <div>{text}</div>
                                            </>
                                            :
                                            <>
                                                <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                <div><i className="fas fa-coins green white"></i> {text}</div>
                                            </>
                                    }

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>

                        </div>
                    </div>

                </div>
            </div >
        </div >
    )
}