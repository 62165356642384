import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import ReactStars from 'react-rating-stars-component'
import Pagination from '../../Pagination'
import CurrencyFormat from 'react-currency-format'
import "./purchases.scss"

export default function App(props) {
    Moment.locale('pt')
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        availableCatalogs: false,
        orders: false,
        updateSale: false
    })
    const [dataOrders, setDataOrders] = useState([])
    const [order, setOrder] = useState([])
    const [dataAvailableCatalogs, setDataAvailableCatalogs] = useState([])
    const [dataItensSelectedCatalog, setDataItensSelectedCatalog] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showRating, setShowRating] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const fieldsRatings = {
        price: 0,
        satisfaction: 0,
        experience: 0,
        quality: 0,
        speedResponse: 0,
        time: 0
    }
    const [ratings, setRatings] = useState(fieldsRatings)
    const fieldsEvaluationReview = {
        price: '',
        satisfaction: '',
        experience: '',
        quality: '',
        speedResponse: '',
        time: ''
    }
    const [reviews, setReviews] = useState(fieldsEvaluationReview)
    const fields = {
        id: 0,
        name: '',
        description: '',
        response: '',
        status: null,
        value: 0,
        idSupplier: ''
    }
    const [text, setText] = useState('')
    const [isError, setIsError] = useState(false)
    const [showModalResp, setShowModalResp] = useState(false)
    const [inputs, setInputs] = useState(fields)
    const [idSelectedCategory, setIdSelectedCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // const currentDataOrders = dataOrders.slice(indexOfFirstPost, indexOfLastPost);
    // const currentDataOrder = dataOrder.slice(indexOfFirstPost, indexOfLastPost);
    // const currentDataCatalogs = dataAvailableCatalogs.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [showModalItens, setShowModalItens] = useState(false)
    const [tmpTotalizadores, setTmpTotalizadores] = useState({
        totalItens: 0,
        nroItens: 0
    })
    const [tmpIdOrder, setTmpIdOrder] = useState(0)
    const [tmpOrderStatus, setTmpOrderStatus] = useState()
    const [isReasonRefuse, setIsReasonRefuse] = useState(false)
    const [description, setDescription] = useState('')
    const [styleButton, setStyleButton] = useState('not-allowed')
    const [isEnableButton, setIsEnableButton] = useState(true)
    const [isEnabled, setIsEnabled] = useState(false)

    const getOrders = async () => {
        setLoaders({ ...loaders, orders: true })
        try {
            const res = await api.get('/purchase-order')
            console.log(`purchase-order =>`, res.data)
            setDataOrders(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({ ...loaders, orders: false })
    }

    const getAvailableCatalogs = async () => {
        setLoaders({ ...loaders, availableCatalogs: true })
        try {
            const res = await api.get('/get-catalogs-available')
            console.log(`resCat =>`, res.data)
            setDataAvailableCatalogs(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({ ...loaders, availableCatalogs: false })
    }

    const updateSale = async (idSale, status) => {

        setLoaders({ ...loaders, updateSale: true })

        try {
            const res = await api.put(`/purchase-order/${idSale}`, {
                "status": status,
                "description": description,
                "reviews": reviews,
                "ratings": ratings
            })

            if (res.data) {
                console.log('res.data', res.data)
                setText('Venda atualizada com sucesso.')
            }

        } catch (err) {
            setText('Não foi possível atualizar a venda. Erro ', err)
            console.log(err)
            setIsError(true)
        } finally {
            setLoaders({ ...loaders, updateSale: false })
            setShowModalResp(true)
            // setShowRating(false)
            closeModalRefused()
            setShowDetails(false)
            getOrders();

        }
    }

    const getSuppliers = async () => {
        // setLoaders({ ...loaders, availableCatalogs: true })
        try {
            const res = await api.get('/company')
            console.log(`res =>`, res.data)
            setSuppliers(res.data)
        } catch (error) {
            console.log("error =>", error)
        }
        // setLoaders({ ...loaders, availableCatalogs: false })
    }

    const getCategories = async () => {
        setLoaders({ ...loaders, users: true })
        try {
            const response = await api.get(`/category?filter=category`)
            console.log('categories -> ', response.data)
            setCategories(response.data)

        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, users: false })
    }

    const getSubCategories = async () => {
        setLoaders({ ...loaders, subCategories: true })
        try {
            const response = await api.get(`/category?filter=subcategory&id=${idSelectedCategory}`)
            console.log('getSubCategories -> ', response.data)
            setSubCategories(response.data)

        } catch (err) {
            console.log(err)
        }
        setLoaders({ ...loaders, subCategories: false })
    }

    const getItensSelectedCatalog = async (idCatalog) => {
        setLoaders({ ...loaders, availableCatalogs: true })

        try {
            const res = await api.get('/get-catalog-available/' + idCatalog)
            console.log(`get-catalog-available =>`, res.data)
            setDataItensSelectedCatalog(res.data)
            setShowModalItens(true)
        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({ ...loaders, availableCatalogs: false })
    }

    const getDetails = async (idOrder) => {
        setLoaders({ ...loaders, details: true })
        setTmpIdOrder(idOrder)

        try {
            const res = await api.get('/purchase-order/' + idOrder)

            if (res.data.item_orders) {
                console.log(`getDetails =>`, res.data)
                setOrder(res.data.item_orders)
                let quantityItems = 0
                res.data.item_orders.forEach(o => {
                    quantityItems += o.quantity
                });
                console.log('quantityItems', quantityItems)
                setTmpTotalizadores({
                    totalItens: res.data.total_trix,
                    nroItens: quantityItems
                })
                setTmpOrderStatus(res.data.status)
                setShowDetails(true);
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        }
        setLoaders({ ...loaders, details: false })
    }

    // precisei criar 6 métodos, pois o reactstarts é engessado nesse aspecto
    const ratingChangedPrice = (newRating) => {
        setRatings({ ...ratings, price: newRating })
    }
    const ratingChangedSatisfaction = (newRating) => {
        setRatings({ ...ratings, satisfaction: newRating })
    }
    const ratingChangedExperience = (newRating) => {
        setRatings({ ...ratings, experience: newRating })
    }
    const ratingChangedQuality = (newRating) => {
        setRatings({ ...ratings, quality: newRating })
    }
    const ratingChangedSpeedResponse = (newRating) => {
        setRatings({ ...ratings, speedResponse: newRating })
    }
    const ratingChangedTime = (newRating) => {
        setRatings({ ...ratings, time: newRating })
    }

    function handleModal() {
        setShowModal(!showModal);
    }

    function handleCancel() {
        setShowModal(false);
        setShowDetails(false)
        getOrders();
        setLoaders({ ...loaders, updateSale: false })
        setShowModalResp(true)
        setShowRating(false)
        setReviews(fieldsEvaluationReview)
        setRatings(fieldsRatings)
    }

    function handleRating() {
        setShowDetails(false)
        setShowRating(true);
        //AQUI VAI FICAR O UPDATE
    }

    function closeModalRefused() {
        setIsReasonRefuse(false)
        setShowRating(false)
        setDescription('')
        setReviews(fieldsEvaluationReview)
        setRatings(fieldsRatings)
    }

    // confirma se o tamanho do input é >= 5 ⤵
    useEffect(() => {
        if ((reviews.satisfaction.length >= 5 && ratings.satisfaction > 0) &&
            (reviews.price.length >= 5 && ratings.price > 0) &&
            (reviews.experience.length >= 5 && ratings.experience > 0) &&
            (reviews.quality.length >= 5 && ratings.quality > 0) &&
            (reviews.speedResponse.length >= 5 && ratings.speedResponse > 0) &&
            (reviews.time.length >= 5 && ratings.time > 0)) {
            setIsEnabled(true)
            setStyleButton('pointer')
        } else {
            setIsEnabled(false)
            setStyleButton('not-allowed')
        }

    }, [reviews, ratings])

    useEffect(() => {
        getOrders()
        getAvailableCatalogs()
        getSuppliers()
        getCategories()
    }, [])

    useEffect(() => {
        if (idSelectedCategory) { getSubCategories() }
    }, [idSelectedCategory])

    useEffect(() => {
        if (description.length >= 5) {
            setIsEnableButton(false)
            setStyleButton('pointer')
        } else {
            setIsEnableButton(true)
            setStyleButton('not-allowed')
        }
    }, [description])

    useEffect(() => {
        console.log('reviews', reviews)
        console.log('ratings', ratings)

        console.log(
            reviews.satisfaction.length, ratings.satisfaction,
            reviews.price.length, ratings.price,
            reviews.experience.length, ratings.experience,
            reviews.quality.length, ratings.quality,
            reviews.speedResponse.length, ratings.speedResponse,
            reviews.time.length, ratings.time
        )
    }, [reviews, ratings])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>

                        <div className="menu-center">
                            <div className="filters-menu">
                                <button disabled={loaders.orders}
                                    onClick={() => getOrders()}
                                    style={{ marginLeft: 10, width: '120px' }}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.orders
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>

                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >

                                <div className="row-forms">
                                    {
                                        loaders.orders
                                            ?
                                            <div>
                                                Buscando Compras...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title"><i className="flaticon-381-network white "></i>&nbsp;Gerenciar Compras</label>
                                                </div>
                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            <th className="sorting_asc"> ID </th>
                                                            <th className="sorting_asc">Data/Hora</th>
                                                            <th className="sorting_asc">Valor</th>
                                                            <th className="sorting_asc">Status</th>
                                                            <th className="sorting_asc">Fornecedor</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            dataOrders.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">OC-{row.id}</td>
                                                                    <td className="sorting_asc">
                                                                        {Moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                                                                    </td>
                                                                    <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.total_trix)}</td>
                                                                    <td className="sorting_asc">
                                                                        {
                                                                            row.status === 0 ?
                                                                                <div style={{ color: 'red' }}>Negado <i className="la la-times-circle red la-lg"></i></div>
                                                                                : row.status === 1 ?
                                                                                    <div style={{ color: 'green' }}>Pronto para Entrega <i className="la la-check-circle green la-lg"></i></div>
                                                                                    : row.status === 2 ?
                                                                                        <div style={{ color: 'orange' }}>Aguardando Vendedor <i className="la la-info-circle la-lg"></i></div>
                                                                                        : row.status === 3 ?
                                                                                            <div style={{ color: 'gray' }}>Aceito <i className="la la-check-circle green la-lg"></i></div>
                                                                                            : row.status === 4 ?
                                                                                                <div style={{ color: 'gray' }}>Concluído <i className="la la-check-circle blue la-lg"></i></div>
                                                                                                : row.status === 5 ?
                                                                                                    <div style={{ color: 'gray' }}>Produto Entregue <i className="la la-legal blue la-lg"></i></div>
                                                                                                    : row.status === 9 ?
                                                                                                        <div style={{ color: 'blue' }}>Estornado <i className="la la-times-circle blue la-lg"></i></div>
                                                                                                        :
                                                                                                        <div>Não Identificado</div>
                                                                        }
                                                                    </td>

                                                                    {/* Coluna Fornecedor */}
                                                                    <td className="sorting_asc">
                                                                        {row.company_seller.name}
                                                                    </td>

                                                                    <td className="sorting_asc" style={{ width: '300px' }} >
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => getDetails(row.id)}>
                                                                                    <i className="la la-info-circle yellow la-lg" /> Detalhes
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                    </tbody>

                                                </table>
                                                {/* <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={dataOrders.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                /> */}
                                            </>
                                    }
                                </div>
                            </section>

                            {/* selecao itens catalogo */}
                            <Rodal
                                visible={showModalItens}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => setShowModalItens(false)}
                            >
                                <div className="close-rodal" onClick={() => setShowModalItens(false)}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">Itens do Catálogo</div>
                                <div className="detal-modal">

                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc"> ID </th>
                                                        <th className="sorting_asc">Nome</th>
                                                        <th className="sorting_asc">Categoria</th>
                                                        <th className="sorting_asc">Subcategoria</th>
                                                        <th className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor</th>
                                                        <th className="sorting_asc">Incluido</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        dataItensSelectedCatalog.length > 0 && dataItensSelectedCatalog.items ?
                                                            dataItensSelectedCatalog.items.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    <td className="sorting_asc">{row.id}</td>
                                                                    <td className="sorting_asc">{row.name}</td>
                                                                    <td className="sorting_asc">
                                                                        {row.category ? row.category.name : ''}
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        {row.sub_category ? row.sub_category.name : ''}
                                                                    </td>
                                                                    <td className="sorting_asc">{row.description}</td>
                                                                    <td className="sorting_asc">R$ {utils.formatMoneyBRL(row.trix_price)}</td>
                                                                    <td className="sorting_asc" style={{ width: '100px' }} >
                                                                        {/* <input
                                                                        type="checkbox"
                                                                        checked={row.isIncluded}
                                                                        onChange={(e) => handleChangeCheckbox(row.id, e.target.checked)} 
                                                                    /> */}
                                                                    </td>
                                                                </tr>
                                                            )
                                                            : <><tr><td>DDDD</td></tr></>}

                                                </tbody>

                                            </table>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button
                                            // onClick={() => saveCatalog()} 
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            Salvar
                                        </button>
                                        <button onClick={handleCancel} style={{ marginLeft: 10, width: '120px' }} type="button" className="btn btn-danger">
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/* Avaliação */}
                            <Rodal
                                visible={showRating}
                                animation="slideLeft"
                                customStyles={{
                                    width: '60%',
                                    height: '505px',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                                showCloseButton={true}
                                onClose={() => closeModalRefused()}
                            >

                                {/* >Botão fechar Rodal */}
                                <div className="close-rodal" onClick={() => closeModalRefused()}>
                                    <Close fontSize="large" style={{ cursor: 'pointer', marginTop: -4 }} />
                                </div>

                                {/* Container modal avaliações */}
                                <div className="modal-evaluation">
                                    {/* satisfação atendimento */}
                                    <Container className="card-rating" style={{ flexFlow: 'column' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    SATISFAÇÃO COM O ATENDIMENTO?
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars
                                                    count={5}
                                                    value={ratings.satisfaction}
                                                    onChange={ratingChangedSatisfaction}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.satisfaction}
                                            onChange={(e) => setReviews({
                                                ...reviews, satisfaction: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                    {/* velocidade resposta */}
                                    <Container className="card-rating" style={{ flexFlow: 'column' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    VELOCIDADE DE RESPOSTA
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars

                                                    count={5}
                                                    onChange={ratingChangedSpeedResponse}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.speedResponse}
                                            onChange={(e) => setReviews({
                                                ...reviews, speedResponse: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                    {/* tempo entrega */}
                                    <Container className="card-rating" style={{ flexFlow: 'column', height: '200px !important' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    TEMPO DE ENTREGA
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars

                                                    count={5}
                                                    onChange={ratingChangedTime}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.time}
                                            onChange={(e) => setReviews({
                                                ...reviews, time: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                </div>
                                <div className="modal-evaluation">
                                    {/* preço com mercado */}
                                    <Container className="card-rating" style={{ flexFlow: 'column' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    O PREÇO ESTA CONDIZENTE COM O MERCADO?
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars

                                                    count={5}
                                                    onChange={ratingChangedPrice}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.price}
                                            onChange={(e) => setReviews({
                                                ...reviews, price: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                    {/* qualidade */}
                                    <Container className="card-rating" style={{ flexFlow: 'column' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    QUALIDADE DO PRODUTO OU SERVIÇO
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars

                                                    count={5}
                                                    onChange={ratingChangedQuality}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.quality}
                                            onChange={(e) => setReviews({
                                                ...reviews, quality: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                    {/* experiencia */}
                                    <Container className="card-rating" style={{ flexFlow: 'column' }}>
                                        <div>
                                            <Row>
                                                <Col className="descricao">
                                                    QUAL SUA EXPERIÊNCIA COM A PLATAFORMA?
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <ReactStars
                                                    // value={ratings}
                                                    count={5}
                                                    onChange={ratingChangedExperience}
                                                    size={30}
                                                    activeColor="#ffd700"
                                                />
                                            </Col>
                                        </Row>

                                        <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            value={reviews.experience}
                                            onChange={(e) => setReviews({
                                                ...reviews, experience: e.target.value
                                            })}
                                            placeholder="Mínimo 5 caracteres"

                                        />

                                    </Container>
                                </div>

                                {/* botões */}
                                <div className="buttons-rodal">
                                    <button
                                        onClick={() => updateSale(tmpIdOrder, '1')}
                                        style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px', cursor: styleButton }}
                                        type="button"
                                        className="btn btn-success"
                                        disabled={!isEnabled}
                                    >
                                        Avaliar
                                    </button>
                                    <button
                                        onClick={handleCancel}
                                        style={{ marginLeft: 10, width: '110px', fontSize: 12, padding: '5px' }}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Rodal>

                            {/* detalhes order */}
                            <Rodal
                                visible={showDetails}
                                animation="slideLeft"
                                customStyles={{
                                    width: '70%',
                                    height: 'auto',
                                    marginTop: '1%',
                                    bottom: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                                showCloseButton={true}
                                onClose={() => handleCancel()}>
                                <div className="close-rodal" onClick={() => handleCancel()}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="title">Compra</div>
                                <div className="detal-modal">
                                    <Container style={{ marginBottom: '16px', textAlign: 'initial' }}>
                                        <Row style={{ marginBottom: '16px' }}>
                                            {/* nro itens */}
                                            <Col>
                                                <label className="text-label">Nro. Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={tmpTotalizadores.nroItens}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                            {/* total itens */}
                                            <Col>
                                                <label className="text-label">Total Itens</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    value={"R$" + utils.formatMoneyBRL(tmpTotalizadores.totalItens)}
                                                    disabled
                                                    className="form-control"
                                                    aria-describedby="inputGroupPrepend2"
                                                />
                                            </Col>
                                        </Row>
                                        {/* grid itens */}
                                        <Row>
                                            <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                <thead>
                                                    <tr role='row'>
                                                        <th className="sorting_asc">Item</th>
                                                        <th className="sorting_asc">Categoria</th>
                                                        <th className="sorting_asc">Subcategoria</th>
                                                        <th className="sorting_asc">Descrição</th>
                                                        <th className="sorting_asc">Valor</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        loaders.details ?
                                                            <div>
                                                                Buscando Itens...
                                                                <ThreeDots
                                                                    color="#0ccbec"
                                                                    height={40}
                                                                    width={40}
                                                                    type="ThreeDots"
                                                                />
                                                            </div>
                                                            : order.length > 0 ?
                                                                order.map((row, idx) =>
                                                                    <tr role="row" key={idx}>
                                                                        <td className="sorting_asc">{row.item.id}</td>
                                                                        <td className="sorting_asc">{row.item.category ? row.item.category.name : ''}</td>
                                                                        <td className="sorting_asc">{row.item.sub_category ? row.item.sub_category.name : ''}</td>
                                                                        <td className="sorting_asc">{row.item.description}</td>
                                                                        <td className="sorting_asc">
                                                                            R$ {utils.formatMoneyBRL(row.current_price)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                : <></>
                                                    }
                                                </tbody>

                                            </table>
                                        </Row>
                                    </Container>
                                    {/* botoes */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {
                                            tmpOrderStatus == 1 || tmpOrderStatus == 2 ?
                                                <>
                                                    {/* <button
                                                        onClick={() => setIsReasonRefuse(true)}
                                                        style={{ marginLeft: 10, width: '150px' }}
                                                        type="button"
                                                        className="btn btn-warning"
                                                    >
                                                        Rejeitar Compra
                                                    </button> */}
                                                    {
                                                        tmpOrderStatus == 1 &&
                                                        <button
                                                            onClick={() => handleRating()}
                                                            style={{ marginLeft: 10, width: '150px' }}
                                                            type="button"
                                                            className="btn btn-success"
                                                        >
                                                            Retirar Produto
                                                        </button>
                                                    }
                                                </>
                                                : <></>
                                        }

                                        <button
                                            onClick={handleCancel}
                                            style={{ marginLeft: 10, width: '120px' }}
                                            type="button"
                                            className="btn btn-danger"
                                        >
                                            Voltar
                                        </button>
                                    </div>
                                </div>
                            </Rodal>

                            {/*rejeitar compra */}
                            <Rodal
                                visible={isReasonRefuse}
                                animation="flip"
                                customStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', height: '300px' }}
                                showCloseButton={true}
                                onClose={() => (false)}
                            >
                                <div className="close-rodal" onClick={() => setIsReasonRefuse(false)} style={{ top: 0 }}>
                                    <Close onClick={e => (false)} fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>

                                <div className="detal-modal" style={{ border: '2px solid #000', backgroundColor: 'rgba(255, 255, 255, 0.45)' }}>
                                    {/* titulo */}
                                    <div className="content-modal">
                                        <div style={{ textAlign: "center", marginBottom: "15px" }}>
                                            <h2 style={{ fontSize: "30px", }}>Rejeitar Compra</h2>
                                        </div>

                                        <label style={{ fontWeight: 'bold', textAlign: 'start' }}>Digite o motivo: </label>
                                        <textarea
                                            value={description}
                                            required
                                            onChange={e => setDescription(e.target.value)}
                                            type="text"
                                            placeholder="Mínimo 5 caracteres"
                                            minlength="5"
                                            style={{ height: '80px', maxHeight: '80px' }}

                                        />

                                    </div>

                                    <div className="buttonsModal">
                                        <button className="buttonModal" style={{ cursor: styleButton }} disabled={isEnableButton} onClick={() => updateSale(tmpIdOrder, '0')}>ENVIAR</button>
                                        <button className="buttonModal" onClick={() => setIsReasonRefuse(false)}>VOLTAR</button>
                                    </div>
                                </div>

                            </Rodal>

                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
