import React, { useState, useEffect } from "react"
import photo from '../../../../images/profile/small/user.jpg'
import logo from '../../../../images/logo-02.png'
import icon from '../../../../images/favicon.ico'
import "./menu.css"
import "./panel.scss"
import "./panel.css"
import 'rodal/lib/rodal.css'
import { getIdProfile, getCompanyType, logout } from "../../../../services/auth"

export default function Menus(props) {

    const [showMenu, setShowMenu] = useState(localStorage.getItem('ID_MENU'))
    // const [showItem, setShowItem] = useState('')
    const [activeItem, setActiveItem] = useState(localStorage.getItem('ID_ITEM'))
    const [user, setUser] = useState(localStorage.getItem('NAME_PROFILE'))
    const [companyType, setCompanyType] = useState('0')
    const ID_PROFILE = getIdProfile()
    const COMPANY_TYPE = getCompanyType()

    const identifyMenu = (idMenu) => {

        idMenu !== showMenu ? setShowMenu(idMenu) : setShowMenu()
        localStorage.setItem('ID_MENU', idMenu)
        // console.log(idMenu)
    }
    // const identifyItem = (idItem) => {

    //   idItem !== showItem ? setShowItem(idItem) : setShowItem('')

    //   console.log(idItem)

    // }
    const showActiveItem = (idItem) => {
        localStorage.setItem('ID_ITEM', idItem)
        // console.log(idItem)
        idItem !== activeItem ? setActiveItem(idItem) : setActiveItem('')
    }

    useEffect(() => {
        setUser(localStorage.getItem('NAME_PROFILE'))
        // console.log('COMPANY_TYPE RENDER =>', COMPANY_TYPE, getCompanyType())
        // console.log('ID_PROFILE RENDER =>', ID_PROFILE)

        if (getCompanyType() !== null) {
            setCompanyType(getCompanyType())
        }

    }, [])

    return (
        <div className="menu">
            <div className={props.toggleMenu ? "menu-icons active" : "menu-icons"} >
                <div className="items"><img className="logo-panel" src={icon} alt="logo panel" /></div>
                <div className="items"><img className="photo-profile" style={{ maxWidth: '40%' }} src={photo} alt="profile" /></div>
                <div className="items"><i className="la la-users white la-2x"></i></div>
                <div className="items"><i className="la la-building-o la-lg"></i></div>
                <div className="items"><i className="la la-th-list white la-lg"></i></div>
                <div className="items"><i className="fas fa-coins white"></i></div>
                <div className="items"><i className="flaticon-381-network white"></i></div>
            </div>
            <div className="sub-menus" style={{ display: props.toggleMenu ? "none" : "inline" }} >
                {/* nome, foto e logo */}
                <div className="top-sub-menu">
                    <div onClick={() => { props.props.history.push('/panel') }} className="logo-menu" >
                        <img className="logo-panel" src={logo} alt="logo panel" />
                    </div>
                    <div className="photo-menu">
                        <div className="photo">
                            <img className="photo-profile" style={{ maxWidth: '40%' }} src={photo} alt="profile" />
                        </div>
                        <div className="name">{user}</div>
                    </div>
                </div>

                <hr className="hr" />

                <div className="footer-sub-menu">
                    <div className="list">
                        <div style={{
                            width: '93%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            margin: '0 auto',
                            border: '0px dashed #FFF'
                        }}>
                            <div className="menu-bar">
                                <PrivateMenus props={props.props} />
                                <div
                                    onClick={() => logout(props)}
                                    style={{ justifyContent: 'center' }}
                                    className="menu-item"
                                    id="button-logof"
                                >
                                    <i className="fas fa-sign-out-alt white fa-lg"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

    function Users(props) {

        return (
            <>
                {/* Users */}
                <div className="menu-item" key={'2'} onClick={() => identifyMenu('2')}>
                    <div className="icon"><i className="la la-users white la-lg" /></div>
                    Usuários
                    <b className={showMenu === '2' ? 'jss1389 jss1392' : 'jss1389'} />
                </div>

                <div
                    className={showMenu === '2' ? 'sub-menu active-menu' : 'sub-menu'}
                    key={21}
                    onClick={() => {
                        showActiveItem('21');
                        props.props.history.push('/list-users')
                    }}
                >
                    <div className="sub-item">
                        <span className={activeItem === '21' ? 'active-item' : ''}>
                            <i className="la la-users white la-lg" />
                            &nbsp;&nbsp;Gerenciar
                        </span>
                    </div>
                </div>

                {
                    ID_PROFILE === '1' || companyType === '1' ?
                        <div className={showMenu === '2' ? 'sub-menu active-menu' : 'sub-menu'} key={22}
                            onClick={() => {
                                showActiveItem('22');
                                props.props.history.push('/create-users')
                            }}
                        >
                            <div className="sub-item">
                                <span className={activeItem === '22' ? 'active-item' : ''}>
                                    <i className="la la-user-plus white la-lg" />
                                    &nbsp;&nbsp;Criar
                                </span>
                            </div>
                        </div>
                        : <></>
                }
                {/* Users */}
            </>
        )
    }

    function Business(props) {
        return (
            <>
                <div className="menu-item" key={'3'} onClick={() => identifyMenu('3')}>
                    <div className="icon"><i className="la la-building-o la-lg"></i></div>Empresas
                    <b className={showMenu === '3' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>

                <div
                    className={showMenu === '3' ? 'sub-menu active-menu' : 'sub-menu'}
                    onClick={() => {
                        showActiveItem('31');
                        props.props.history.push('/list-business')
                    }}>
                    <div className="sub-item">
                        <span className={activeItem === '31' ? 'active-item' : ''}>
                            <i className="la la-building-o la-lg"></i>&nbsp;&nbsp;Gerenciar
                        </span>
                    </div>
                </div>

                <div
                    className={showMenu === '3' ? 'sub-menu active-menu' : 'sub-menu'}
                    onClick={() => {
                        showActiveItem('32');
                        props.props.history.push('/create-business')
                    }}>
                    <div className="sub-item">
                        <span className={activeItem === '32' ? 'active-item' : ''}>
                            <i className="fas fa-plus white"></i>&nbsp;&nbsp;Criar
                        </span>
                    </div>
                </div>
            </>
        )
    }

    function Lots(props) {
        return (
            <>
                <div className="menu-item" key={'4'} onClick={() => identifyMenu('4')}>
                    <div className="icon"><i className="la la-th-list white la-lg"></i></div>Lotes
                    <b className={showMenu === '4' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>
                {
                    companyType === '1' ?
                        <div
                            className={showMenu === '4' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'42'}
                            onClick={() => {
                                showActiveItem('42');
                                props.props.history.push('/payments-lots')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '42' ? 'active-item' : ''}>
                                    <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Pagar
                                </span>
                            </div>
                        </div>
                        :
                        companyType === '3' &&

                        <div
                            className={showMenu === '4' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'41'}
                            onClick={() => {
                                showActiveItem('41');
                                props.props.history.push('/closing-lots')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '41' ? 'active-item' : ''}>
                                    <i className="la la-th-list white la-lg" />
                                    &nbsp;&nbsp;Fechar
                                </span>
                            </div>
                        </div>
                }

            </>
        )
    }

    function Credits(props) {
        return (
            <>
                <div className="menu-item" key={'5'} onClick={() => identifyMenu('5')}>
                    <div className="icon"><i className="fas fa-coins white"></i></div>Meu Saldo
                    <b className={showMenu === '5' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>
                {/* Master */}
                {
                    COMPANY_TYPE == 1 ?
                        <>
                            <div
                                className={showMenu === '5' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'51'}
                                onClick={() => {
                                    showActiveItem('51');
                                    props.props.history.push('/credits-generator')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '51' ? 'active-item' : ''}>
                                        <i className="fas fa-coins white"></i>
                                        &nbsp;&nbsp;Enviar
                                    </span>
                                </div>
                            </div>

                            <div
                                className={showMenu === '5' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'52'}
                                onClick={() => {
                                    showActiveItem('52');
                                    props.props.history.push('/credit-approval')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '52' ? 'active-item' : ''}>
                                        <i className="fas fa-coins white"></i>
                                        &nbsp;&nbsp;Aprovar
                                    </span>
                                </div>
                            </div>

                            <div
                                className={showMenu === '5' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'55'}
                                onClick={() => {
                                    showActiveItem('55');
                                    props.props.history.push('/credit-remove')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '55' ? 'active-item' : ''}>
                                        <i className="fas fa-coins white"></i>
                                        &nbsp;&nbsp;Estornar
                                    </span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div
                                className={showMenu === '5' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'54'}
                                onClick={() => {
                                    showActiveItem('54');
                                    props.props.history.push('/credit-return')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '54' ? 'active-item' : ''}>
                                        <i className="fas fa-money white"></i>
                                        &nbsp;&nbsp;Devolver
                                    </span>
                                </div>
                            </div>

                            {ID_PROFILE == 2 &&
                                <>
                                    <div
                                        className={showMenu === '5' ? 'sub-menu active-menu' : 'sub-menu'}
                                        key={'53'}
                                        onClick={() => {
                                            showActiveItem('53');
                                            props.props.history.push('/credit-management')
                                        }}>
                                        <div className="sub-item">
                                            <span className={activeItem === '53' ? 'active-item' : ''}>
                                                <i className="fas fa-money white"></i>
                                                &nbsp;&nbsp;Gerenciar
                                            </span>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                }
            </>
        )
    }

    function Budgets(props) {
        return (
            ID_PROFILE !== '4' ?
                <>
                    <div className="menu-item" key={'6'} onClick={() => identifyMenu('6')}>
                        <div className="icon"><i className="flaticon-381-network white"></i></div>Orçamentos
                        <b className={showMenu === '6' ? 'jss1389 jss1392' : 'jss1389'}></b>
                    </div>
                    {
                        props.company_type === '2' &&
                        <div
                            className={showMenu === '6' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'61'}
                            onClick={() => {
                                showActiveItem('61');
                                props.props.history.push('/open-budgets')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '61' ? 'active-item' : ''}>
                                    <i className="flaticon-381-network white "></i>&nbsp;&nbsp;Negociar
                                </span>
                            </div>
                        </div>
                    }
                    <div
                        className={showMenu === '6' ? 'sub-menu active-menu' : 'sub-menu'}
                        key={'62'}
                        onClick={() => {
                            showActiveItem('62');
                            props.props.history.push('/manager-budgets')
                        }}>
                        <div className="sub-item">
                            <span className={activeItem === '62' ? 'active-item' : ''}>
                                <i className="flaticon-381-network white "></i>&nbsp;&nbsp;Negociações
                            </span>
                        </div>
                    </div>
                </>
                : <></>
        )
    }

    function Reports(props) {

        switch (COMPANY_TYPE) {

            case '1':
                return (
                    <>
                        <div className="menu-item" key={'1'} onClick={() => identifyMenu('1')}>
                            <div className="icon"><i className="fas fa-chart-pie white"></i></div>Relatórios
                            <b className={showMenu === '1' ? 'jss1389 jss1392' : 'jss1389'}></b>
                        </div>

                        <div
                            className={showMenu === '1' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={11}
                            onClick={() => {
                                showActiveItem('11');
                                props.props.history.push('/report-master-partner')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '11' ? 'active-item' : ''}>
                                    <i className="fas fa-chart-pie white"></i>&nbsp;&nbsp;Dashboard Gerencial - PC
                                </span>
                            </div>
                        </div>

                        <div
                            className={showMenu === '1' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={12}
                            onClick={() => {
                                showActiveItem('12');
                                props.props.history.push('/report-master-supplier')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '12' ? 'active-item' : ''}>
                                    <i className="fas fa-chart-pie white"></i>&nbsp;&nbsp;Dashboard Gerencial - PF
                                </span>
                            </div>
                        </div>
                    </>
                )

            case '2':
                return (
                    <>
                        <div className="menu-item" key={'1'} onClick={() => identifyMenu('1')}>
                            <div className="icon"><i className="fas fa-chart-pie white"></i></div>Relatórios
                            <b className={showMenu === '1' ? 'jss1389 jss1392' : 'jss1389'}></b>
                        </div>

                        <div
                            className={showMenu === '1' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={11}
                            onClick={() => {
                                showActiveItem('11');
                                props.props.history.push('/report-partner-commercial')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '11' ? 'active-item' : ''}>
                                    <i className="fas fa-chart-pie white"></i>&nbsp;&nbsp;Dashboard - PC
                                </span>
                            </div>
                        </div>

                        <div
                            className={showMenu === '1' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={12}
                            onClick={() => {
                                showActiveItem('12');
                                props.props.history.push('/financial-extract')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '12' ? 'active-item' : ''}>
                                    <i className="fas fa-chart-pie white"></i>&nbsp;&nbsp;Extrato Financeiro
                                </span>
                            </div>
                        </div>
                    </>
                )

            case '3':
                return (
                    <>
                        <div className="menu-item" key={'1'} onClick={() => identifyMenu('1')}>
                            <div className="icon"><i className="fas fa-chart-pie white"></i></div>Relatórios
                            <b className={showMenu === '1' ? 'jss1389 jss1392' : 'jss1389'}></b>
                        </div>

                        <div
                            className={showMenu === '1' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={11}
                            onClick={() => {
                                showActiveItem('11');
                                props.props.history.push('/report-partner-supplier')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '11' ? 'active-item' : ''}>
                                    <i className="fas fa-chart-pie white"></i>&nbsp;&nbsp;Dashboard - PF
                                </span>
                            </div>
                        </div>
                    </>
                )

            default:
        }

    }

    function Pdv(props) {
        let text = companyType === '2' ? 'Compras' : 'Vendas'
        return (
            <>
                <div className="menu-item" key={'8'} onClick={() => identifyMenu('8')}>
                    <div className="icon"><i className="la la-th-list white la-lg"></i></div>{text}
                    <b className={showMenu === '8' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>
                {
                    companyType === '2' ?
                        <>
                            <div
                                className={showMenu === '8' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'81'}
                                onClick={() => {
                                    showActiveItem('81');
                                    props.props.history.push('/manage-purchases')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '81' ? 'active-item' : ''}>
                                        <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Minhas Compras
                                    </span>
                                </div>
                            </div>

                            <div
                                className={showMenu === '8' ? 'sub-menu active-menu' : 'sub-menu'}
                                key={'82'}
                                onClick={() => {
                                    showActiveItem('82');
                                    props.props.history.push('/buy-itens')
                                }}>
                                <div className="sub-item">
                                    <span className={activeItem === '82' ? 'active-item' : ''}>
                                        <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Comprar Agora
                                    </span>
                                </div>
                            </div>
                        </>
                        :
                        companyType === '3' &&
                        <div
                            className={showMenu === '8' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'82'}
                            onClick={() => {
                                showActiveItem('82');
                                props.props.history.push('/manage-sales')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '82' ? 'active-item' : ''}>
                                    <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Minhas Vendas
                                </span>
                            </div>
                        </div>
                }
            </>
        )
    }

    function Categories(props) {
        return (
            <>
                <div className="menu-item" key={'9'} onClick={() => identifyMenu('9')}>
                    <div className="icon"><i className="la la-th-list white la-lg"></i></div>Categorias
                    <b className={showMenu === '9' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>

                <div
                    className={showMenu === '9' ? 'sub-menu active-menu' : 'sub-menu'}
                    key={'91'}
                    onClick={() => {
                        showActiveItem('91');
                        props.props.history.push('/list-categories')
                    }}>
                    <div className="sub-item">
                        <span className={activeItem === '91' ? 'active-item' : ''}>
                            <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Gerenciar
                        </span>
                    </div>
                </div>

                <div
                    className={showMenu === '9' ? 'sub-menu active-menu' : 'sub-menu'}
                    key={'92'}
                    onClick={() => {
                        showActiveItem('92');
                        props.props.history.push('/create-categories')
                    }}>
                    <div className="sub-item">
                        <span className={activeItem === '92' ? 'active-item' : ''}>
                            <i className="la la-th-list white la-lg"></i>&nbsp;&nbsp;Criar
                        </span>
                    </div>
                </div>
            </>
        )
    }

    function ItemCatalog(props) {
        return (
            <>
                <div className="menu-item" key={'7'} onClick={() => identifyMenu('7')}>
                    <div className="icon"><i className="flaticon-381-box-2 white"></i></div>Produtos e Serviços
                    <b className={showMenu === '7' ? 'jss1389 jss1392' : 'jss1389'}></b>
                </div>
                {
                    props.company_type == 3 &&
                    <>
                        <div
                            className={showMenu === '7' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'71'}
                            onClick={() => {
                                showActiveItem('71');
                                props.props.history.push('/manage-items')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '71' ? 'active-item' : ''}>
                                    <i className="flaticon-381-box-2 white "></i>&nbsp;&nbsp;Ver Itens
                                </span>
                            </div>
                        </div>

                        <div
                            className={showMenu === '7' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'73'}
                            onClick={() => {
                                showActiveItem('73');
                                props.props.history.push('/catalog')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '73' ? 'active-item' : ''}>
                                    <i className="flaticon-381-box-2 white "></i>&nbsp;&nbsp;Ver Catálogos
                                </span>
                            </div>
                        </div>
                    </>
                }
                {
                    props.company_type == 1 &&
                    <>
                        <div
                            className={showMenu === '7' ? 'sub-menu active-menu' : 'sub-menu'}
                            key={'72'}
                            onClick={() => {
                                showActiveItem('72');
                                props.props.history.push('/approve-items')
                            }}>
                            <div className="sub-item">
                                <span className={activeItem === '72' ? 'active-item' : ''}>
                                    <i className="flaticon-381-box-2 white "></i>&nbsp;&nbsp;Aprovar
                                </span>
                            </div>
                        </div>
                    </>
                }

            </>
        )
    }

    function Panel(props) {
        return (
            <> -- Panel --</>
        )
    }

    function PrivateMenus(props) {
        // se o usuário é o SYS
        if (ID_PROFILE === '1') {
            return (
                <>
                    <Users props={props.props} />
                    <Business props={props.props} />
                    <Categories props={props.props} />
                </>
            )
        } else {
            /*
                CompanyType 1 = Master
                CompanyType 2 = Parceiro Comercial
                CompanyType 3 = Parceiro Fornecedor
             */
            switch (companyType) {
                case '1':
                    return (
                        <>
                            {/* <Users props={props.props} /> */}
                            {/* <Business props={props.props} /> */}
                            <Credits props={props.props} />
                            <Lots props={props.props} />
                            <Reports props={props.props} />
                            {/* <Itens props={props.props} /> */}
                            <ItemCatalog props={props.props} company_type={companyType} />
                        </>
                    )
                case '2': // Parceiro Comercial
                    return (
                        <>
                            {/* <Users props={props.props} /> */}
                            <Credits props={props.props} />
                            <Budgets props={props.props} company_type={companyType} />
                            <Reports props={props.props} />
                            {/* <Itens props={props.props} /> */}
                            <Pdv props={props.props} />
                        </>
                    )
                case '3': // Parceiro Fornecedor // apenas acesso manager budget
                    return (
                        <>
                            {/* <Users props={props.props} /> */}
                            <Lots props={props.props} />
                            <Budgets props={props.props} company_type={companyType} />
                            <Reports props={props.props} />
                            {/* <Itens props={props.props} /> */}
                            <Pdv props={props.props} />
                            <ItemCatalog props={props.props} company_type={companyType} />
                        </>
                    )

                default:
                    return (
                        <Panel props={props.props} />
                    )
            }
        }
    }
}