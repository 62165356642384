import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import CurrencyFormat from 'react-currency-format'
import { Close } from '@material-ui/icons'

export default function App(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [dataCompanies, setDataCompanies] = useState([])
    const [loaders, setLoaders] = useState({
        companies: false,
        register: false
    })
    const fields = {
        idCompany: '',
        identifycator: '',
        value: 0
    }
    const [inputs, setInputs] = useState(fields)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('Crédito lançado com sucesso!')

    const [showModal, setShowModal] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    const styleInputs = {
        width: '30%'
    }

    const getCompanies = async () => {

        setLoaders({ ...loaders, companies: true })

        try {

            const res = await api.get('/company?filter=2')

            setDataCompanies(res.data)

            console.log(`companies`, res)

        } catch (error) {
            console.log("error =>", error)
        }
        setLoaders({ ...loaders, companies: false })
    }

    const generator = async () => {

        setLoaders({ ...loaders, register: true })
        setText('')
        setIsError(false)

        try {

            const res = await api.post('/wallet-entry', {

                credits: [
                    {
                        id_company: Number(inputs.idCompany),
                        description: inputs.identifycator,
                        value: Number(inputs.value)
                    }
                ]

            })
            
            setInputs(fields)
            setText('Crédito lançado com sucesso!')
            console.log(`res=>`, res)


        } catch (error) {

            console.log("error =>", error.response)
            setIsError(true)
            setText(error.response.data.message)
        }
        setShowModal(true)
        setLoaders({ ...loaders, register: false })

    }

    useEffect(() => { 

        if(inputs.idCompany === '' || inputs.identifycato === '' || inputs.value === 0){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
        console.log('inputs', inputs) 

    }, [inputs])

    useEffect(() => { getCompanies() }, [])


    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">
                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>
                    <div className="data">
                        <div className="panel">
                            <section className="form-create-users" style={{ display: 'flex' }}>
                                <label className="text-label-title"><i className="fas fa-coins white"></i>&nbsp; Lançamentos de Créditos</label>
                                <div className="separator" />

                                <div className="row-forms">
                                    <div className="form-group">
                                        <label className="text-label">Nome *</label>
                                        <select
                                            value={inputs.idCompany}
                                            className='form-control form-control-lg'
                                            id='inlineFormCustomSelect'
                                            style={styleInputs}
                                            onChange={e => setInputs({ ...inputs, idCompany: e.target.value })}
                                        >
                                            <option>{loaders.companies ? 'Carregando...' : '-- EMPRESAS --'}</option>
                                            {
                                                dataCompanies.map((row, idx) =>
                                                    <option key={idx} value={row.id}> {row.name}</option>

                                                )
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="row-forms">
                                    <div className="form-group">
                                        <label className="text-label">Identificador *</label>
                                        <input
                                            type="text"
                                            style={styleInputs}
                                            className="form-control"
                                            placeholder="Nome..."
                                            value={inputs.identifycator}
                                            onChange={e => setInputs({ ...inputs, identifycator: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="row-forms">
                                    <div className="form-group">
                                        <label className="text-label">Valor *</label>
                                        <CurrencyFormat style={{ color: '#4bc019' }}
                                            currency="BRL"
                                            prefix='R$ '
                                            placeholder="Valor..."
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="form-control"

                                            style={{
                                                width: '30%',
                                                color: '#4bc019',
                                                fontSize: '1rem',
                                                fontWeight: '500'
                                            }}
                                            value={inputs.value}
                                            onValueChange={(values) => {
                                                const { floatValue } = values
                                                // console.log(typeof(floatValue))
                                                setInputs({ ...inputs, value: floatValue > 0 ? floatValue : 0 })
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    <button disabled={loaders.register || isDisabled} onClick={() => generator()} style={{ width: '12%' }} type="button" className="btn btn-info">
                                        {
                                            loaders.register
                                                ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;Processando...
                                                </>
                                                :
                                                'Gerar Crédito'
                                        }
                                    </button>
                                </div>
                            </section>
                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}

                            >
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <>
                                                <div><i className="fas fa-times error fa-2x"></i></div>
                                                <div> {text}</div>
                                            </>
                                            :
                                            <>
                                                <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                <div><i className="fas fa-coins green white"></i> {text}</div>
                                            </>
                                    }

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}

