import React, { useState, useEffect } from 'react'
import Menu from '../Panel/menu'
import api from '../../../../services/api'
import Rodal from 'rodal'
import ThreeDots from 'react-loader-spinner'
import { Dropdown } from "react-bootstrap"
import { FilterList, Close } from '@material-ui/icons'
import Moment from 'moment'
import utils from '../../../../utils/utils'
import Pagination from '../../Pagination'

export default function App(props) {
    Moment.locale('pt')
    const [toggleMenu, setToggleMenu] = useState(false)
    const [loaders, setLoaders] = useState({
        itens: false
    })
    const [statusItem, setStatusItem] = useState('todos')
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [isError, setIsError] = useState(false)
    const [text, setText] = useState('')
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    )
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentData = data.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const getItens = async () => {
        setLoaders({ ...loaders, itens: true })

        try {
            const res = await api.get(`/fetch-pending-items/${statusItem}`)

            if (res.data) {
                console.log('res', res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error =>", error.response.data.message)
        } finally {
            setLoaders({ ...loaders, itens: false })
        }
    }

    const approveDennyItem = async (idItem, status) => {
        setLoaders({ ...loaders, saveItem: true })
        setText('')
        setIsError(false)

        try {
            const res = await api.post('/approve-deny-item', {
                id: idItem,
                approve: status
            })
            setText('Item atualizado com sucesso')
            console.log(`res=>`, res)
        } catch (err) {
            console.log('err -> ', err)
            setIsError(true)
            setText('Não foi possivel atualizar o item. Erro ' + err)
        } finally {
            setLoaders({ ...loaders, saveItem: false })
            setShowModal(true)
            getItens()
        }
    }

    useEffect(() => {
        getItens()
    }, [])

    return (
        <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
            <Menu props={props} toggleMenu={toggleMenu} />
            <div className="container-panel">
                <div className="body-container">

                    <nav className="menu-container">
                        <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
                            <div className="bar">
                                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
                            </div>
                        </div>
                        <div className="menu-center">

                            <div className="filters-menu">

                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{ height: '35px', marginLeft: 10 }}>
                                        <FilterList fontSize="default" className="blue" />
                                    </div>
                                </div>
                                <select
                                    style={{ width: '15%' }}
                                    className="form-control"
                                    onChange={(e) => { setStatusItem(e.target.value) }}
                                >
                                    <option value="todos">TODOS OS ITENS</option>
                                    <option value='aprovado'>APROVADO</option>
                                    <option value='pendente'>PENDENTE</option>
                                    <option value='reprovado'>REPROVADO</option>
                                </select>

                                <button disabled={loaders.itens}
                                    onClick={() => getItens()}
                                    style={{ marginLeft: 10, width: '120px' }}
                                    id="search-charge-back"
                                    type="button"
                                    className="btn btn-info"
                                >
                                    {
                                        loaders.itens
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <i className="fas fa-search fa-rotate-90"></i>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="menu-right">
                            <i className="fas fa-bell orange fa-lg"></i>
                        </div>
                    </nav>

                    <div className="data">
                        <div className="panel" style={{ padding: '1%' }}>
                            <section className="container-trix" style={{ marginTop: '20px', minHeight: '70%', justifyContent: 'center' }} >

                                <div className="row-forms">
                                    {
                                        loaders.itens
                                            ?
                                            <div>
                                                Buscando Itens...
                                                <ThreeDots
                                                    color="#0ccbec"
                                                    height={40}
                                                    width={40}
                                                    type="ThreeDots"
                                                />
                                            </div>
                                            :
                                            <>
                                                <div className="row-forms" style={{ flexDirection: 'row' }}>
                                                    <label className="text-label-title">
                                                        <i className="flaticon-381-network white "></i>&nbsp;Aprovar Itens
                                                    </label>
                                                </div>

                                                <table className='table display mb-4 dataTablesCard  dataTable no-footer'>
                                                    <thead>
                                                        <tr role='row'>
                                                            {/* <th className="sorting_asc"> ID </th> */}
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Empresa</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Nome</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Fabricante</th>
                                                            {/* <th style={{ textAlign: 'start' }} className="sorting_asc">Categoria</th>
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Subcategoria</th> */}
                                                            {/* <th style={{ textAlign: 'start' }} className="sorting_asc">Descrição</th> */}
                                                            <th style={{ textAlign: 'start' }} className="sorting_asc">Marca</th>
                                                            <th className="sorting_asc">R$ Mercado</th>
                                                            <th className="sorting_asc">R$ eTrix</th>
                                                            <th className="sorting_asc">Diferença</th>
                                                            <th className="sorting_asc">Status</th>
                                                            <th className="sorting_asc">Ações</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            currentData.map((row, idx) =>
                                                                <tr role="row" key={idx}>
                                                                    {/* <td style={{ textAlign: 'start' }} className="sorting_asc">{row.id}</td> */}
                                                                    <td style={{ textAlign: 'left', width: 200 }}>{row.company.name}</td>
                                                                    <td style={{ textAlign: 'left', width: 200 }}>{row.name}</td>
                                                                    <td style={{ textAlign: 'left', width: 100 }}>{row.brand}</td>
                                                                    <td style={{ textAlign: 'left', width: 100 }}>{row.brand}</td>
                                                                    <td style={{ textAlign: 'left', width: 100 }}>
                                                                        R$ {utils.formatMoneyBRL(row.market_price)}
                                                                    </td>
                                                                    <td style={{ textAlign: 'left', width: 100 }}>
                                                                        R$ {utils.formatMoneyBRL(row.trix_price)}
                                                                    </td>
                                                                    <td style={{ textAlign: 'left', width: 100 }}>
                                                                        {
                                                                            row.market_price - row.trix_price > 0 ?
                                                                                <p style={{ color: 'green', marginTop: 'auto', marginBottom: 'auto' }}>
                                                                                    R$ {utils.formatMoneyBRL(row.market_price - row.trix_price)}
                                                                                </p>
                                                                                : row.market_price - row.trix_price === 0 ?
                                                                                    <p style={{ color: 'orange', marginTop: 'auto', marginBottom: 'auto' }}>
                                                                                        R$ {utils.formatMoneyBRL(row.market_price - row.trix_price)}
                                                                                    </p>
                                                                                    : <p style={{ color: 'red', marginTop: 'auto', marginBottom: 'auto' }}>
                                                                                        R$ {utils.formatMoneyBRL(row.market_price - row.trix_price)}
                                                                                    </p>
                                                                        }

                                                                    </td>
                                                                    <td style={{ textAlign: 'center', width: 50 }}>
                                                                        {
                                                                            row.active === 0 ?
                                                                                <div style={{ color: 'orange' }}>
                                                                                    <i className="la la-info-circle la-lg" />Pendente
                                                                                </div>
                                                                                : row.active === 1 ?
                                                                                    <div style={{ color: 'green' }}>
                                                                                        <i className="la la-check-circle green la-lg" />Aprovado
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{ color: 'red' }}>
                                                                                        <i className="la la-times-circle red la-lg" />Reprovado
                                                                                    </div>
                                                                        }
                                                                    </td>
                                                                    <td className="sorting_asc">
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle
                                                                                variant="success"
                                                                                className="light sharp i-false"
                                                                                style={{
                                                                                    width: '150px',
                                                                                    height: '20px'
                                                                                }}
                                                                            >
                                                                                {svg1}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => approveDennyItem(row.id, 1)}>
                                                                                    <i className="la la-check-circle green la-lg" /> Aprovar
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => approveDennyItem(row.id, 0)}>
                                                                                    <i className="la la-close red la-lg" /> Reprovar
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={data.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                            </>
                                    }
                                </div>
                            </section>
                            {/* modal mensagem retorno */}
                            <Rodal
                                visible={showModal}
                                animation="flip"
                                customStyles={{ width: '50%', height: 'calc(35%)', marginTop: '10%' }}
                                showCloseButton={true}
                                onClose={() => setShowModal(false)}

                            >
                                <div className="close-rodal" onClick={() => { setShowModal(false) }}>
                                    <Close fontSize="large" style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="modal-error">
                                    {
                                        isError
                                            ?
                                            <>
                                                <div><i className="fas fa-times error fa-2x"></i></div>
                                                <div> {text}</div>
                                            </>
                                            :
                                            <>
                                                <div><i className="far fa-check-circle green fa-2x"></i></div>
                                                <div><i className="fas fa-coins green white"></i> {text}</div>
                                            </>
                                    }

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto' }}>
                                    <div className="modal-error-buttons" />
                                </div>
                            </Rodal>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
