import React, { useState } from 'react'
import "./panel.scss"
import "./panel.css"
import Menu from './menu'
import logo from '../../../../images/logo-01.png'
export default function App(props) {

  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className={toggleMenu ? 'wrapper menu-active' : 'wrapper'}>
      <Menu props={props} toggleMenu={toggleMenu} />
      <div className="container-panel">
        <div className="body-container">
          <nav className="menu-container">
            <div onClick={() => setToggleMenu(!toggleMenu)} className="menu-left" id="menu-bar">
              <div className="bar">
                <div className={toggleMenu ? 'bar1 active-bar1' : 'bar1'}></div>
                <div className={toggleMenu ? 'bar2 active-bar2' : 'bar2'}></div>
                <div className={toggleMenu ? 'bar3 active-bar3' : 'bar3'}></div>
              </div>
            </div>
            <div className="menu-center">

            </div>
            <div className="menu-right">
              <i className="fas fa-bell orange fa-lg"></i>
            </div>
          </nav>
          <div className="data">
            <div className="panel" style={{justifyContent: 'center'}}>
              <div className="logo"><img src={logo} alt="Trix" /></div>
              {/* <h1>Em Desenvolvimento...</h1> */}
              {/* <ThreeDots
              color="rgb(241, 141, 33)"
              height={70}
              width={70}
              type="ThreeDots"
            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}